import { Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, catchError, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from './notification/notification.service';
import { ApiProxy } from '../../constants/api-proxy';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  apiUrl: string = environment.url;
  notify: NotificationService;
  private countriesUrl = 'assets/js/CountryCodes.json';


  constructor(private http: HttpClient, private api: ApiProxy, injector: Injector) {
    this.notify = injector.get(NotificationService);
  }

  login(data: any) {
    let url = this.apiUrl + this.api.user.login();
    return this.http.post(url, data);
  };
  signUp(data: any) {
    let url = this.apiUrl + this.api.user.signUp();
    return this.http.post(url, data);
  };
  personalDetail(data: any) {
    let url = this.apiUrl + this.api.user.personalDetail();
    return this.http.post(url, data);
  };
  personalInfo(data: any) {
    let url = this.apiUrl + this.api.user.personalInfo();
    return this.http.post(url, data);
  };
  loginPIN(data: any) {
    let url = this.apiUrl + this.api.user.loginPIN();
    return this.http.post(url, data);
  };
  dashboard(data: any) {
    let url = this.apiUrl + this.api.user.dashboard();
    return this.http.post(url, data);
  };
  getCurrency(data: any) {
    let url = this.apiUrl + this.api.user.getCurrency();
    return this.http.post(url, data);
  };
  transactionDetail(data: any) {
    let url = this.apiUrl + this.api.user.transactionDetail();
    return this.http.post(url, data);
  };
  getTransactionLimit(data: any) {
    let url = this.apiUrl + this.api.user.getTransactionLimit();
    return this.http.post(url, data);
  };
  getTransactionHistory(data: any) {
    let url = this.apiUrl + this.api.user.getTransactionHistory();
    return this.http.post(url, data);
  };
  event_list() {
    let url = this.apiUrl + this.api.user.event_list();
    return this.http.get(url);
  };
  stripe_event_sender(data: any) {
    let url = this.apiUrl + this.api.user.stripe_event_sender();
    return this.http.post(url, data);
  };
  event_ticket_emails(data: any) {
    let url = this.apiUrl + this.api.user.event_ticket_emails();
    return this.http.post(url, data);
  };
  get_accounts(data: any) {
    let url = this.apiUrl + this.api.user.get_accounts();
    return this.http.post(url, data);
  };
  stripe_ticket_availability(data: any) {
    let url = this.apiUrl + this.api.user.stripe_ticket_availability();
    return this.http.post(url, data);
  };
  stripe_event_transaction_preview(data: any) {
    let url = this.apiUrl + this.api.user.stripe_event_transaction_preview();
    return this.http.post(url, data);
  };
  get_profile(data: any) {
    let url = this.apiUrl + this.api.user.get_profile();
    return this.http.post(url, data);
  };
  cardNumberCheck(cardNumber: any) {
    let url =this.api.user.cardNumberCheck(cardNumber);
    return this.http.get(url);
  };

  getCountriesCode(): Observable<any[]> {
    return this.http.get<any[]>(this.countriesUrl).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Error fetching countries:', error);
        return throwError(error);
      })
    );
  };
}
