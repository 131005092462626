import { Component, OnInit } from '@angular/core';
import { FooterComponent } from '../nav/footer/footer.component';
import { NavBarComponent } from '../nav/nav-bar/nav-bar.component';
import { first } from 'rxjs';
import { ApiService } from '../../service/api.service';

@Component({
  selector: 'app-transaction-limit',
  standalone: true,
  imports: [NavBarComponent, FooterComponent],
  templateUrl: './transaction-limit.component.html',
  styleUrl: './transaction-limit.component.css'
})
export class TransactionLimitComponent implements OnInit {

  constructor(private service: ApiService) { }

  userDetail: any;
  ngOnInit(): void {
    if (typeof localStorage !== 'undefined') {
      this.userDetail = localStorage.getItem('user');
      if (this.userDetail) {
        this.userDetail = JSON.parse(this.userDetail);
      }
    } else {
      console.log('localStorage is not available.');
    }
    this.getTransactionLimit();
  }

  transactionLimit: any;
  getTransactionLimit() {
    if (this.userDetail) {
      const formData = new FormData();
      formData.append('session', this.userDetail.session);
      formData.append('updte', '1');
      this.service.getTransactionLimit(formData).pipe(first()).subscribe((response: any) => {
        if (response) {
          console.log(response);
          this.transactionLimit = response;
        }
      });
    }
  }
}
