<section class="d-flex align-items-center" style="background-color: #15202b; min-height: 100vh;">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <video src="../../../../assets/LolliCash-Video.mp4" autoplay loop playsinline class="image-bg rounded-2"></video>
            <div class="col-12 col-md-8">
                
                <div class="card rounded-10 border-0 shadow-lg p-0 bg-dark text-light">
                    <div class="col-12 card-body p-0" style="position: relative;">
                        <h1 class="text-center mt-4 ">Provide Ticket Information</h1>
                        <form [formGroup]="eventTicketForm" (ngSubmit)="savePersonalInfo()" class="image-bg-form">
                            <div class="form-row d-flex flex-wrap justify-content-between align-items-center">
                                <div class="form-group row col-12 col-md-6">
                                    <label class="col-form-label">First Name:*</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control border-1 border-black" placeholder="Enter your Name"
                                            formControlName="first_name">
                                    </div>
                                </div>
                                <div class="form-group row col-12 col-md-6">
                                    <label class="col-form-label">Address:*</label>
                                    <div class="input-group">
                                        <input placeholder="Enter Address Detail" autocorrect="off" autocapitalize="off"
                                            spellcheck="off" type="text" class="form-control border-1 border-black" #search
                                            formControlName="address" (input)="onAddressInputChange($event)" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-row d-flex flex-wrap justify-content-between align-items-center">
                                <div class="form-group row col-12 col-md-6">
                                    <label class="col-form-label">Last Name:*</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control border-1 border-black" placeholder="Enter your Last Name"
                                            formControlName="last_name">
                                    </div>
                                </div>
                                <div class="form-group row col-12 col-md-6">
                                    <label class="col-form-label">City:*</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control border-1 border-black" placeholder="Enter your City"
                                            value="{{eventTicketForm.get('user_city')?.value}}"
                                            formControlName="user_city">
                                    </div>
                                </div>
                                <div class="form-group row col-12 col-md-6">
                                    <label class="col-form-label">Email:*</label>
                                    <div class="input-group">
                                        <input type="email" class="form-control border-1 border-black" placeholder="Enter your Email"
                                            formControlName="user_email">
                                    </div>
                                    <div *ngIf="eventTicketForm.get('user_email')?.invalid && (eventTicketForm.get('user_email')?.dirty || eventTicketForm.get('user_email')?.touched)" class="text-danger">
                                        <div *ngIf="eventTicketForm.get('user_email')?.errors?.['required']">Email is required.</div>
                                        <div *ngIf="eventTicketForm.get('user_email')?.errors?.['invalidEmail']">Invalid email format.</div>
                                      </div>
                                </div>
                                <div class="form-group row col-12 col-md-6">
                                    <label class="col-form-label">Zip Code:*</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control border-1 border-black" placeholder="Enter your Zip Code"
                                            maxlength="6" value="{{eventTicketForm.get('postal_code')?.value}}"
                                            formControlName="postal_code">
                                    </div>
                                </div>
                            </div>
                            <div class="form-row d-flex flex-wrap justify-content-between align-items-center">
                                <div class="form-group row col-12 col-md-6">
                                    <label class="col-form-label">Mobile Number:*</label>
                                    <div class="col-12 mb-1">
                                        <p-dropdown appendTo="body" [options]="countryCodes" optionValue="code"
                                            optionLabel="name" placeholder="Country Code" [filter]="true"
                                            styleClass="col-12 border-1 border-black" formControlName="country_code">
                                        </p-dropdown>
                                    </div>
                                    <div class="col-12 d-flex flex-wrap">
                                        <input type="text" class="form-control border-1 border-black" id="exampleInputNumber"
                                            aria-describedby="emailHelp" placeholder="Enter Phone Number" minlength="10"
                                            maxlength="10" formControlName="user_mobile">
                                            <div *ngIf="eventTicketForm.get('user_mobile')?.invalid && (eventTicketForm.get('user_mobile')?.dirty || eventTicketForm.get('user_mobile')?.touched)" class="text-danger">
                                                <div *ngIf="eventTicketForm.get('user_mobile')?.errors?.['required']" style="padding-top: 10px;">Phone number is required.</div>
                                                <div *ngIf="eventTicketForm.get('user_mobile')?.errors?.['invalidPhoneNumber']">Invalid phone number.</div>
                                    </div>
                                    </div>
                                </div>
                                <!-- <div class="form-group row col-12 col-md-6">
                                    <label class="col-form-label">Last Name:*</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control border-1 border-black" placeholder="Enter your Last Name"
                                            formControlName="last_name">
                                    </div>
                                </div> -->
                                <div class="form-group row col-12 col-md-6">
                                    <label class="col-form-label">State:*</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control border-1 border-black" placeholder="Enter your State"
                                            value="{{eventTicketForm.get('user_state')?.value}}"
                                            formControlName="user_state">
                                    </div>
                                </div>
                            </div>
                            <div class="form-row d-flex flex-wrap justify-content-end align-items-center">
                                <div class="form-group row col-12 col-md-6">
                                    <label class="col-form-label">Country:*</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control border-1 border-black" placeholder="Enter your Country"
                                            value="{{eventTicketForm.get('country')?.value}}" formControlName="country">
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group row col-12">
                                <label class="col-form-label col-6">Mobile Number:*</label>
                                <div class="col-6 d-flex">
                                    <div class="input-group">
                                        <p-dropdown appendTo="body" [options]="countryCodes" optionValue="code" optionLabel="name"
                                            placeholder="Country Code" [filter]="true" styleClass="border-1 border-black" formControlName="country_code">
                                        </p-dropdown>
                                    </div>
                                    <div class="input-group flex-grow-1 ms-2">
                                        <input type="text" class="form-control border-1 border-black" id="exampleInputNumber"
                                            aria-describedby="emailHelp" placeholder="Enter Phone Number" minlength="10" maxlength="10"
                                            formControlName="user_mobile">
                                        <div *ngIf="eventTicketForm.get('user_mobile')?.invalid && (eventTicketForm.get('user_mobile')?.dirty || eventTicketForm.get('user_mobile')?.touched)"
                                            class="text-danger">
                                            <div *ngIf="eventTicketForm.get('user_mobile')?.errors?.['required']">Phone number is required.</div>
                                            <div *ngIf="eventTicketForm.get('user_mobile')?.errors?.['invalidPhoneNumber']">Invalid phone
                                                number.</div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            
                            <div class="d-flex flex-wrap justify-content-end align-items-center mt-4">
                                <!-- <button type="button" class="btn btn-secondary me-2" (click)="goBack()">Back</button> -->
                                <button type="submit" class="btn btn-danger">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>