<section style="background-color: #15202b;">

    <!-- Nav Bar Start -->
    <app-nav-bar></app-nav-bar>
    <!-- Nav Bar End -->
    <!-- Send Money Section Start -->
    @if(sendShow){
    <section class="section" style="padding-top: 105px; min-height: 100vh;">
        <div class="container">
            <div class="row">
                <h1 class="text-light text-center">Send Money</h1>
                <div class="col-12 col-md-5 mx-auto card rounded-10 border-0 shadow-lg p-4 text-center"
                    style="background: #FFEBEC;">
                    <h2 class="mb-4" style="color: #071e55;">To send money, search with recipient phone number or
                        LolliCash name and
                        follow the process</h2>
                    <div class="col-12 card-body p-0 pt-4">
                        <div class="forn-group row">
                            <div class="form-check form-check-inline col-md-6 m-0 justify-content-center">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" name="mobile" value="mobile" checked
                                        (change)="radioChange($event)">
                                    Mobile
                                </label>
                            </div>
                            <div class="form-check form-check-inline col-md-6 m-0 justify-content-center mb-3">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" name="mobile" value="name"
                                        (change)="radioChange($event)">
                                    LolliCash Name
                                </label>
                            </div>
                            @if(mobileInputShow === true){
                            <div class="form-group py-3 d-flex flex-wrap justify-content-center">
                                <div class="col-12 col-md-8 d-flex flex-wrap justify-content-center p-0">
                                    <div class="col-3 p-0 mb-3">
                                        <p-dropdown appendTo="body" [options]="countryCodes" optionValue="code"
                                            optionLabel="name" placeholder="Country Code" formControlName="countryISO"
                                            [filter]="true" styleClass="col-12 border-1">
                                        </p-dropdown>
                                    </div>
                                    <div class="col-9 p-0">
                                        <input type="text" class="form-control border-1" id="exampleInputNumber"
                                            aria-describedby="emailHelp" placeholder="Enter Phone Number" minlength="10"
                                            maxlength="10">
                                    </div>
                                </div>
                            </div>
                            } @else {
                            <div class="form-group py-3 d-flex flex-wrap justify-content-center">
                                <div class="col-12 col-md-8 d-flex flex-wrap justify-content-center p-0">
                                    <div class="col-12 p-0">
                                        <input type="text" class="form-control border-1" id="exampleInputNumber"
                                            aria-describedby="emailHelp" value="LC">
                                    </div>
                                </div>
                            </div>
                            }
                            <div class="col-12 text-center">
                                <button type="submit" class="col-4 btn btn-danger my-4 py-2"
                                    (click)="goToNext()">Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    }
    <!-- Send Money Section End -->

    @if (sendMoneyShow) {
    <section class="section" style="padding-top: 105px; min-height: 100vh;">
        <div class="container">
            <div class="row">
                <h1 class="text-light text-center">REQUEST PAYMENT</h1>
                <div class="col-12 col-md-6 mx-auto card rounded-10 border-0 shadow-lg p-4 text-center"
                    style="background: #FFEBEC;">
                    <div class="col-12 card-body p-0 pt-4">
                        <img src="../../../assets/user-139.png" alt="user Image" width="200" height="200">
                        <h3 class="text-dark mt-3">daniel ryker</h3>
                        <div class="d-flex flex-wrap justify-content-center">
                            <div class="form-group py-3 text-start col-6">
                                <input type="number" class="form-control border-0" placeholder="0.00"
                                    style="box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, .25) !important;">
                            </div>
                        </div>
                        <h3 style="background-color: #DC3545; display: inline-block; border-radius: 8px;"
                            class="text-light p-2 border-rounded mb-0">USC</h3>
                        <div class="d-flex flex-wrap justify-content-center">
                            <div class="form-group py-3 text-start col-8">
                                <input type="text" class="form-control border-0" placeholder="Description"
                                    style="box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, .25) !important;">
                            </div>
                        </div>
                        <div class="col-12 text-center">
                            <button type="submit" class="col-4 btn btn-outline-danger my-4 py-2 me-3"
                                (click)="cancle()">Cancle</button>
                            <button type="submit" class="col-4 btn btn-danger my-4 py-2" (click)="confirm()">Confirm &
                                Proceed</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    }

    @if (transaction) {
    <section class="section" style="padding-top: 105px; min-height: 100vh;">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 mx-auto card rounded-10 border-0 shadow-lg p-4"
                    style="background: #FFEBEC;">
                    <h1 class="text-center">TRANSACTION REVIEW</h1>
                    <div class="col-12 card-body p-0 pt-4">
                        <p class="border-bottom text-dark mb-0 py-2">Transfer Amount: USD 1</p>
                        <p class="border-bottom text-dark mb-0 py-2">Amount To Receive: INR 1</p>
                        <p class="border-bottom text-dark mb-0 py-2">Transaction Taxes/Fee: No Fee Charge</p>
                        <p class="border-bottom text-dark mb-0 py-2">Total Amount Paid: USD 1</p>
                        <p class="border-bottom text-dark mb-0 py-2">Date & Time: 03-22-2024 02:45 AM</p>
                        <p class="border-bottom text-dark mb-0 py-2">Source of Transaction: Wallet</p>
                        <p class="border-bottom text-dark mb-0 py-2">Description: Transfer to</p>
                        <p class="border-bottom text-dark mb-0 py-2">To: LClol</p>
                        <p class="border-bottom text-dark mb-0 py-2">Receiver Mobile: +919499673369</p>
                        <p class="border-bottom text-dark mb-0 py-2">From: LCravis</p>
                        <p class="border-bottom text-dark mb-0 py-2">Sender Mobile: +16464509293</p>
                        <p class="border-bottom text-dark mb-0 py-2">Reason: Test</p>
                        <div class="col-12 text-center">
                            <button type="submit" class="col-3 btn btn-outline-danger my-4 py-2 me-3"
                                (click)="cancle()">Cancle</button>
                                <button type="submit" class="col-3 btn btn-outline-danger my-4 py-2 me-3">Edit</button>
                            <button type="submit" class="col-3 btn btn-danger my-4 py-2" (click)="cancle()">Send</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    }

    <!-- Footer Start -->
    <app-footer></app-footer>
    <!-- Footer End -->
</section>