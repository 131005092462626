<section style="background-color: #15202b;">

    <!-- Nav Bar Start -->
    <app-nav-bar></app-nav-bar>
    <!-- Nav Bar End -->

    <!-- Bank Account Information Section Start -->
    <section class="section" style="padding-top: 125px; min-height: 100vh;">

        <!-- Add Bank Account Modal -->
        <div class="modal fade" id="AddBankAccount" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="AddBankAccountLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="background-color: #FFEBEC;">
                    <div class="modal-header">
                        <h2 class="modal-title" id="AddBankAccountLabel">Add Bank Account Details</h2>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-row d-flex flex-wrap justify-content-between align-items-center">
                            <div class="form-group row col-12 my-2">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="IFSC Code">
                                </div>
                            </div>
                            <div class="form-group row col-12 my-2">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Account Name">
                                </div>
                            </div>
                            <div class="form-group row col-12 my-2">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Confirm Account Name">
                                </div>
                            </div>
                            <div class="form-group row col-12 my-2">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Account Holder Name">
                                </div>
                            </div>
                            <div class="form-group row col-12 my-2">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Bank Name">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary my-3 col-12"
                            data-bs-dismiss="modal">Cancle</button>
                        <button type="button" class="btn btn-danger col-12">Next</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Add Card Modal -->
        <div class="modal fade" id="AddCard" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="AddCardLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="background-color: #FFEBEC;">
                    <div class="modal-header">
                        <h2 class="modal-title" id="AddCardLabel">Add Debit/ATM Card Details</h2>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-row d-flex flex-wrap justify-content-between align-items-center">
                            <div class="form-group row col-12 my-2">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter Card Number" maxlength="16" minlength="16">
                                </div>
                            </div>
                            <div class="form-group row col-12 col-md-4 my-2">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="MM" maxlength="2" minlength="2">
                                </div>
                            </div>
                            <div class="form-group row col-12 col-md-4 my-2">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="YYYY" minlength="4" maxlength="4">
                                </div>
                            </div>
                            <div class="form-group row col-12 col-md-4 my-2">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="CVV" minlength="3" maxlength="3">
                                </div>
                            </div>
                            <div class="form-group row col-12 my-2">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter Name on Card">
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center pt-3 pb-2">
                                <div class="col-2">
                                    <img src="../../../assets/privacy.png"
                                        style="width: 100%; height: 100%; object-fit: cove;" alt="Privacy Icon">
                                </div>
                                <div class="col-10 px-2">
                                    <p>
                                        Your information is safe and secured - we use industry standard and highly
                                        secure
                                        encryption process to protect your personal information.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary my-3 col-12"
                            data-bs-dismiss="modal">Cancle</button>
                        <button type="button" class="btn btn-danger col-12">Next</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row justify-content-center">
                <!-- <div class="col-12 col-md-8">
                    <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="pills-bank-account-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-bank-account" type="button" role="tab" aria-controls="pills-bank-account"
                                aria-selected="true">External Accounts</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-wallet-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-wallet" type="button" role="tab" aria-controls="pills-wallet"
                                aria-selected="false">LolliCash Wallet</button>
                        </li>
                    </ul>
                    <div class="tab-pane fade show active" id="pills-bank-account" role="tabpanel"
                        aria-labelledby="pills-bank-account-tab">
                        <div class="col-12">
                            <h1 class="text-center mt-4">Bank Information</h1>

                            <button type="button" class="btn btn-outline-danger px-4" data-bs-toggle="modal"
                                data-bs-target="#AddBankAccount">
                                <i class="fi fi-br-plus me-2" style="vertical-align: text-top;"></i> Add Bank Account
                            </button>
                        </div>
                        <div class="col-12 mb-4">
                            <h1 class="text-center mt-4">Card Information</h1>

                            <button type="button" class="btn btn-outline-danger px-3" data-bs-toggle="modal"
                                data-bs-target="#AddCard">
                                <i class="fi fi-br-plus me-2" style="vertical-align: text-top;"></i> Add Debit/ATM Card
                            </button>
                        </div>
                        <div class="col-12 pt-4">
                            <div class="d-flex flex-wrap justify-content-between align-items-center">
                                <div class="debit-card col-4">
                                    <div class="top">
                                        <h2 class="mb-0">Test Name</h2>
                                        <img src="https://cdn-icons-png.flaticon.com/512/1436/1436392.png"
                                            alt="image" />
                                    </div>
                                    <div class="infos">
                                        <section class="card-number">
                                            <p class="mb-0">Card Number</p>
                                            <h1 class="mb-0">4242 4242 4242 4242</h1>
                                        </section>
                                        <div class="bottom">
                                            <aside class="infos--bottom">
                                                <section>
                                                    <p class="mb-0">Expiry date</p>
                                                    <h3 class="mb-0">08/24</h3>
                                                </section>
                                                <section>
                                                    <p class="mb-0">CVV</p>
                                                    <h3 class="mb-0">111</h3>
                                                </section>
                                            </aside>
                                            <aside>
                                                <section>
                                                    <img src="https://seeklogo.com/images/V/VISA-logo-DD37676279-seeklogo.com.png"
                                                        class="brand" alt="image" />
                                                </section>
                                            </aside>
                                        </div>
                                    </div>
                                </div>
                                <div class="debit-card col-4">
                                    <div class="top">
                                        <h2 class="mb-0">Test Name</h2>
                                        <img src="https://cdn-icons-png.flaticon.com/512/1436/1436392.png"
                                            alt="image" />
                                    </div>
                                    <div class="infos">
                                        <section class="card-number">
                                            <p class="mb-0">Card Number</p>
                                            <h1 class="mb-0">4242 4242 4242 4242</h1>
                                        </section>
                                        <div class="bottom">
                                            <aside class="infos--bottom">
                                                <section>
                                                    <p class="mb-0">Expiry date</p>
                                                    <h3 class="mb-0">08/24</h3>
                                                </section>
                                                <section>
                                                    <p class="mb-0">CVV</p>
                                                    <h3 class="mb-0">111</h3>
                                                </section>
                                            </aside>
                                            <aside>
                                                <section>
                                                    <img src="https://seeklogo.com/images/V/VISA-logo-DD37676279-seeklogo.com.png"
                                                        class="brand" alt="image" />
                                                </section>
                                            </aside>
                                        </div>
                                    </div>
                                </div>
                                <div class="debit-card col-4">
                                    <div class="top">
                                        <h2 class="mb-0">Test Name</h2>
                                        <img src="https://cdn-icons-png.flaticon.com/512/1436/1436392.png"
                                            alt="image" />
                                    </div>
                                    <div class="infos">
                                        <section class="card-number">
                                            <p class="mb-0">Card Number</p>
                                            <h1 class="mb-0">4242 4242 4242 4242</h1>
                                        </section>
                                        <div class="bottom">
                                            <aside class="infos--bottom">
                                                <section>
                                                    <p class="mb-0">Expiry date</p>
                                                    <h3 class="mb-0">08/24</h3>
                                                </section>
                                                <section>
                                                    <p class="mb-0">CVV</p>
                                                    <h3 class="mb-0">111</h3>
                                                </section>
                                            </aside>
                                            <aside>
                                                <section>
                                                    <img src="https://seeklogo.com/images/V/VISA-logo-DD37676279-seeklogo.com.png"
                                                        class="brand" alt="image" />
                                                </section>
                                            </aside>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-wallet" role="tabpanel" aria-labelledby="pills-wallet-tab">
                        <div class="col-12">
                            <img src="../../../assets/wallet.gif" alt="Wallet Icon" style="width: 250px; height: 250px;">
                        </div>
                    </div>
                </div> -->
                <div class="col-12 col-md-8">
                    <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="pills-all-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all"
                                aria-selected="true">External Accounts</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-send-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-send" type="button" role="tab" aria-controls="pills-send"
                                aria-selected="false">LolliCash Wallet</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-all" role="tabpanel"
                            aria-labelledby="pills-all-tab">
                            <div class="col-12">
                                <h1 class="text-center mt-4 text-light">Bank Information</h1>

                                <button type="button" class="btn btn-outline-danger px-4" data-bs-toggle="modal"
                                    data-bs-target="#AddBankAccount">
                                    <i class="fi fi-br-plus me-2" style="vertical-align: text-top;"></i> Add Bank
                                    Account
                                </button>
                            </div>
                            <div class="col-12 mb-4">
                                <h1 class="text-center mt-4 text-light">Card Information</h1>

                                <button type="button" class="btn btn-outline-danger px-3" data-bs-toggle="modal"
                                    data-bs-target="#AddCard">
                                    <i class="fi fi-br-plus me-2" style="vertical-align: text-top;"></i> Add Debit/ATM
                                    Card
                                </button>
                            </div>
                            <div class="col-12 pt-4">
                                <div class="d-flex flex-wrap justify-content-between align-items-center">
                                    <div class="debit-card col-4">
                                        <div class="top">
                                            <h2 class="mb-0">Test Name</h2>
                                            <img src="https://cdn-icons-png.flaticon.com/512/1436/1436392.png"
                                                alt="image" />
                                        </div>
                                        <div class="infos">
                                            <section class="card-number">
                                                <p class="mb-0">Card Number</p>
                                                <h1 class="mb-0">4242 4242 4242 4242</h1>
                                            </section>
                                            <div class="bottom">
                                                <aside class="infos--bottom">
                                                    <section>
                                                        <p class="mb-0">Expiry date</p>
                                                        <h3 class="mb-0">08/24</h3>
                                                    </section>
                                                    <section>
                                                        <p class="mb-0">CVV</p>
                                                        <h3 class="mb-0">111</h3>
                                                    </section>
                                                </aside>
                                                <aside>
                                                    <section>
                                                        <img src="https://seeklogo.com/images/V/VISA-logo-DD37676279-seeklogo.com.png"
                                                            class="brand" alt="image" />
                                                    </section>
                                                </aside>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="debit-card col-4">
                                        <div class="top">
                                            <h2 class="mb-0">Test Name</h2>
                                            <img src="https://cdn-icons-png.flaticon.com/512/1436/1436392.png"
                                                alt="image" />
                                        </div>
                                        <div class="infos">
                                            <section class="card-number">
                                                <p class="mb-0">Card Number</p>
                                                <h1 class="mb-0">4242 4242 4242 4242</h1>
                                            </section>
                                            <div class="bottom">
                                                <aside class="infos--bottom">
                                                    <section>
                                                        <p class="mb-0">Expiry date</p>
                                                        <h3 class="mb-0">08/24</h3>
                                                    </section>
                                                    <section>
                                                        <p class="mb-0">CVV</p>
                                                        <h3 class="mb-0">111</h3>
                                                    </section>
                                                </aside>
                                                <aside>
                                                    <section>
                                                        <img src="https://seeklogo.com/images/V/VISA-logo-DD37676279-seeklogo.com.png"
                                                            class="brand" alt="image" />
                                                    </section>
                                                </aside>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="debit-card col-4">
                                        <div class="top">
                                            <h2 class="mb-0">Test Name</h2>
                                            <img src="https://cdn-icons-png.flaticon.com/512/1436/1436392.png"
                                                alt="image" />
                                        </div>
                                        <div class="infos">
                                            <section class="card-number">
                                                <p class="mb-0">Card Number</p>
                                                <h1 class="mb-0">4242 4242 4242 4242</h1>
                                            </section>
                                            <div class="bottom">
                                                <aside class="infos--bottom">
                                                    <section>
                                                        <p class="mb-0">Expiry date</p>
                                                        <h3 class="mb-0">08/24</h3>
                                                    </section>
                                                    <section>
                                                        <p class="mb-0">CVV</p>
                                                        <h3 class="mb-0">111</h3>
                                                    </section>
                                                </aside>
                                                <aside>
                                                    <section>
                                                        <img src="https://seeklogo.com/images/V/VISA-logo-DD37676279-seeklogo.com.png"
                                                            class="brand" alt="image" />
                                                    </section>
                                                </aside>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-send" role="tabpanel" aria-labelledby="pills-send-tab">
                            <div class="col-12 text-center">
                                <img src="../../../assets/wallet.gif" alt="Wallet Icon"
                                    style="width: 250px; height: 250px;">
                            </div>
                            <div class="col-12 text-center mt-4">
                                <h3 class="mb-0 text-light">Available Wallet Balance</h3>
                                <h1 style="font-size: 4rem;" class="text-light">INR 100</h1>
                                <button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#AddMoneyToWallet" (click)="addMoney()"><i class="fi fi-br-plus me-2" style="vertical-align: text-top;"></i>Add Money To Wallet</button>
                            </div>
                            <div class="col-12 text-center mt-4">
                                <button class="btn btn-outline-danger me-2 px-4" (click)="depositMoney()">Deposit Money</button>
                                <button class="btn btn-outline-danger ms-2 px-4">Send Money</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Bank Account Information Section End -->

    <!-- Footer Start -->
    <app-footer></app-footer>
    <!-- Footer End -->
</section>