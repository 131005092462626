<section style="background-color: #15202b;">

    <!-- Nav Bar Start -->
    <app-nav-bar></app-nav-bar>
    <!-- Nav Bar End -->

    <!-- Add Money Section Start -->
    <section class="section" style="padding-top: 125px; min-height: 100vh;">
        <div class="container">
            <div class="row">
                <h1 class="text-light text-center">ADD MONEY TO WALLET</h1>
                <div class="col-12 col-md-6 mx-auto card rounded-10 border-0 shadow-lg p-4 text-center"
                    style="background: #FFEBEC;">
                    <div class="col-12 card-body p-0 pt-4 text-center">
                        <div class="d-flex flex-wrap justify-content-center">
                            <div class="form-group py-3 text-start col-6">
                                <input type="number" class="form-control border-0" placeholder="0.00"
                                    style="box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, .25) !important;">
                            </div>
                        </div>
                        <div class="col-12 text-center">
                            <button type="submit" class="col-4 btn btn-outline-danger py-2 me-3">Cancle</button>
                            <button type="submit" class="col-4 btn btn-danger my-4 py-2">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Add Money Section End -->

    <!-- Footer Start -->
    <app-footer></app-footer>
    <!-- Footer End -->
</section>