<section style="background-color: #15202b;">

    <!-- Nav Bar Start -->
    <app-nav-bar></app-nav-bar>
    <!-- Nav Bar End -->

    <!-- Account Support Section Start -->
    <section class="section" style="padding-top: 105px; height: 100vh;">
        <div class="container">
            <div class="row justify-content-center">
                <h1 class="text-light text-center">ACCOUNT SUPPORT</h1>
                <div class="col-12 col-md-6 mx-auto card rounded-10 border-0 shadow-lg p-4"
                    style="background: #FFEBEC;">
                    <div class="col-12 card-body p-0 py-4 d-flex flex-wrap justify-content-center align-items-center">
                        <div class="col-12 col-md-8 d-flex flex-wrap justify-content-between align-items-center py-3 rounded mb-4"
                            style="border: 1px solid #DC3545;">
                            <div class="col-10 ps-4">
                                <h4 class="mb-0"><i class="fi fi-sr-messages text-danger" style="vertical-align: middle;"></i> Chat With Us</h4>
                            </div>
                            <div class="col-2 text-end pe-4">
                                <i class="fi fi-br-arrow-right text-danger" style="vertical-align: middle;"></i>
                            </div>
                        </div>
                        <div class="col-12 col-md-8 d-flex flex-wrap justify-content-between align-items-center py-3 rounded mb-4"
                            style="border: 1px solid #DC3545;">
                            <div class="col-10 ps-4">
                                <h4 class="mb-0"><i class="fi fi-sr-envelope text-danger" style="vertical-align: middle;"></i> Chat Us Email</h4>
                            </div>
                            <div class="col-2 text-end pe-4">
                                <i class="fi fi-br-arrow-right text-danger" style="vertical-align: middle;"></i>
                            </div>
                        </div>
                        <div class="col-12 col-md-8 d-flex flex-wrap justify-content-between align-items-center py-3 rounded mb-4"
                            style="border: 1px solid #DC3545;">
                            <div class="col-10 ps-4">
                                <h4 class="mb-0"><i class="fi fi-sr-phone-call text-danger" style="vertical-align: middle;"></i> +233546 872 809</h4>
                            </div>
                            <div class="col-2 text-end pe-4">
                                <i class="fi fi-br-arrow-right text-danger" style="vertical-align: middle;"></i>
                            </div>
                        </div>
                        <div class="col-12 col-md-8 d-flex flex-wrap justify-content-between align-items-center py-3 rounded"
                            style="border: 1px solid #DC3545;">
                            <div class="col-10 ps-4">
                                <h4 class="mb-0"><i class="fi fi-sr-phone-call text-danger" style="vertical-align: middle;"></i> +16464509293</h4>
                            </div>
                            <div class="col-2 text-end pe-4">
                                <i class="fi fi-br-arrow-right text-danger" style="vertical-align: middle;"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Account Support Section End -->

    <!-- Footer Start -->
    <app-footer></app-footer>
    <!-- Footer End -->
</section>