import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { default as firebase } from 'firebase/compat/app';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  constructor(private afAuth: AngularFireAuth) { }

  sendOtp(phoneNumber: string): Observable<any> {
    const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'compact', //invisible
      'callback': (response: any) => {
        console.log('reCAPTCHA solved:', response);
      },
      'expired-callback': () => {
        console.log('reCAPTCHA challenge expired');
      },
    },);

    return new Observable(observer => {
      this.afAuth
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          observer.next(confirmationResult);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  verifyOtp(verificationId: string, otp: string): Observable<any> {
    // Verify OTP
    return new Observable(observer => {
      this.afAuth
        .signInWithCredential(firebase.auth.PhoneAuthProvider.credential(verificationId, otp))
        .then((userCredential) => {
          observer.next(userCredential);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  signOut(): Observable<any> {
    return new Observable(observer => {
      this.afAuth
        .signOut()
        .then(() => {
          observer.next();
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }
  
}
