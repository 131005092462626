<!-- <div class="loader-body">
    <div class="loader"></div>
</div> -->

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true" #myModal>
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <h2 class="modal-title mb-0" id="staticBackdropLabel">New Event!!!</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeModel()"></button>
            </div> -->
            <div class="modal-body p-0 pt-3">
                <h1 class="text-center text-danger">{{eventData?.event_name}}</h1>
                <div class="text-center">
                    <img src="{{imageUrl + eventData?.image_url}}" alt="Event Image" width="100%" height="100%"
                        style="object-fit: cover;">
                </div>
                <!-- <h2 class="mt-3 text-center">{{eventData?.ticket_name}}</h2>
                <div class="text-center">
                    <img src="{{imageUrl + eventData?.ticket_image_url}}" alt="Ticket Image" width="300" height="300"
                        style="object-fit: cover;">
                </div>
                <h2 class="mt-3">Price: {{eventData?.price}}</h2> -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModel()">Close</button>
                <button type="button" class="btn btn-outline-danger" [routerLink]="['/ticket_list']">Buy a Ticket</button>
            </div>
        </div>
    </div>
</div>

<section style="background-color: #15202b;">
    <!-- Nav Bar Start -->
    <app-nav-bar></app-nav-bar>
    <!-- Nav Bar End -->

    <!-- Slider Start -->
    <section style="padding-top: 105px;">
        <div class="container-fluid">
            <div class="row">
                <!-- <img src="../../../assets/slider-bg.jpg" style="width: 100%; height: 550px;" alt="Slider Image"> -->
                <div class="slider-content" style="padding: 10% 1%;">
                    <div class="col-4 text-center">
                        <h1 class="text-light text-center mb-4">Hello {{timezone}}, {{userDetail?.user_name}}</h1>
                        <h2 class="text-light pt-2" style="letter-spacing: 2px;">
                            You're welcome to LolliCash. The most Secure, Fast, and Convenient Money Wallet System.
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Slider End -->

    <!-- Card Start -->
    <section class="section">
        <div class="container">
            <div class="row">
                <div class="col-4">
                    <div class="card rounded-10 border-0 shadow-lg p-4">
                        <div class="d-flex justify-content-around mb-4 card-img overflow-hidden">
                            <img class="card-img mx-auto " src="../../../assets/wallet.png" style="width: 50px;"
                                alt="Wallet Card image">
                        </div>
                        <div class="card-body p-0 text-center">
                            <h2 class="card-title fw-semibold">{{userCurrency?.currency}}
                                {{userDetail?.current_balance}}</h2>
                            <p>Available Wallet Balance</p>
                            <div class="d-flex flex-wrap justify-content-between align-items-center">
                                <a href="#" class="mt-4 px-4 fw-semibold text-decoration-none"
                                    style="letter-spacing: 1px;">Deposit | Withdraw</a>
                                <a href="#" class="mt-4 px-4 fw-semibold text-decoration-none"
                                    style="letter-spacing: 1px;">Add Money</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card rounded-10 border-0 shadow-lg p-4">
                        <div class="d-flex justify-content-around mb-4 card-img overflow-hidden">
                            <img class="card-img mx-auto " src="../../../assets/request money.png" style="width: 50px;"
                                alt="Request Money Card image">
                        </div>
                        <div class="card-body p-0 text-center">
                            <h2 class="card-title fw-semibold">Request Money</h2>
                            <p>Tap for request a money</p>
                            <div class="d-flex flex-wrap justify-content-center align-items-center">
                                <a href="/request_money" class="mt-4 px-4 fw-semibold d-block text-decoration-none"
                                    style="letter-spacing: 1px;">Request a
                                    Money</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card rounded-10 border-0 shadow-lg p-4">
                        <div class="d-flex justify-content-around mb-4 card-img overflow-hidden">
                            <img class="card-img mx-auto " src="../../../assets/send money.png" style="width: 50px;"
                                alt="Send Money Card image">
                        </div>
                        <div class="card-body p-0 text-center">
                            <h2 class="card-title fw-semibold">Send Money</h2>
                            <p>Tap for send money</p>
                            <div class="d-flex flex-wrap justify-content-center align-items-center">
                                <a href="/send_money" class="mt-4 px-4 fw-semibold d-block text-decoration-none"
                                    style="letter-spacing: 1px;">Send Money</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Card End -->

    <!-- Current Transaction Start -->
    <section class="section">
        <div class="container">
            <div class="row">
                <div class="d-flex flex-wrap justify-content-between align-items-center mb-2">
                    <h2 class="mb-0 text-light">Current Transactions</h2>
                    <a [routerLink]="['/all_transactions']" class="text-danger fw-semibold text-decoration-none" style="letter-spacing: 2px;">See
                        All</a>
                </div>
                <div *ngIf="transactionDetails.length > 0; else noDataMessage">
                    <table mat-table [dataSource]="transactionDetails" class="rounded-table">
                        <!-- Define columns -->
                        <ng-container matColumnDef="image">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell class="py-2" *matCellDef="let element"><img src="{{element.sender_profile}}"
                                    alt="User Image" class="rounded-circle" style="width: 50px; object-fit: cover;">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="moneyDetail">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell class="py-2" *matCellDef="let element">
                                <p class="fw-semibold mb-0 text-dark">
                                    {{element.transaction_type}}
                                </p>
                                <p class="mb-0 text-dark" [ngClass]="{
                                    'btn-success': element.status === 'Success',
                                    'btn-warning': element.status === 'Pending',
                                    'btn-danger': element.status === 'Failed'
                                  }">
                                    {{element.status}}
                                </p>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="money">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell class="py-2" *matCellDef="let element">
                                <button type="button" class="btn btn-success">INR {{element.amount_to_receive}}</button>
                            </td>
                        </ng-container>

                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <ng-template #noDataMessage>
                    <div class="alert alert-danger text-center">No data available in table</div>
                </ng-template>
            </div>
        </div>
    </section>
    <!-- Current Transaction End -->

    <!-- Footer Start -->
    <app-footer></app-footer>
    <!-- Footer End -->
</section>