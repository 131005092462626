import { Component } from '@angular/core';
import { FooterComponent } from '../nav/footer/footer.component';
import { NavBarComponent } from '../nav/nav-bar/nav-bar.component';

@Component({
  selector: 'app-add-money',
  standalone: true,
  imports: [NavBarComponent, FooterComponent],
  templateUrl: './add-money.component.html',
  styleUrl: './add-money.component.css'
})
export class AddMoneyComponent {

}
