<section style="min-height: 100vh; background-color: #15202b;">

    <!-- Nav Bar Start -->
    <app-nav-bar></app-nav-bar>
    <!-- Nav Bar End -->

    <!-- About Us Slider Section Start -->
    <section class="section" style="padding-top: 105px;">
        <div class="container-fluid">
            <div class="row">
                <div class="about-content" style="padding: 12% 1%;">
                    <div class="col-4 text-center">
                        <h1 class="text-light text-center mb-4">About Us</h1>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- About Us Slider Section End -->

    <!-- About Us Section Start -->
    <section class="section pt-0">
        <div class="col-12 col-md-8 mx-auto card rounded-10 border-0 shadow-lg p-4 text-center">
            <h2>We provide a convenient Money TransferÂ Wallet System</h2>
            <div class="col-12 card-body p-0">
                <p>
                    LolliCash is a secure Wallet system to send and receive money transfer payments. With a
                    vision-driven
                    goal to make both domestic and international money transfers easier and more convenient worldwide.
                    The Profile AI QR functionality makes it easier for individuals and small-scale businesses to
                    efficiently scan and pay for transactions effortlessly. Now it is easier to send and receive money
                    from international countries without depending on Mobile Money or issues from other fintech
                    services. LolliCash leverages its modern, mobile, and API-driven platform and collaborates with the
                    world's leading brands to serve consumers through the LolliCash Wallet system, its
                    direct-to-consumer digital business, and its global retail network.
                </p>
            </div>
        </div>
    </section>
    <!-- About Us Section End -->

    <!-- Footer Start -->
    <app-footer></app-footer>
    <!-- Footer End -->
</section>