<section style="background-color: #15202b;">

    <!-- Nav Bar Start -->
    <app-nav-bar></app-nav-bar>
    <!-- Nav Bar End -->

    <!-- History Section Start -->
    <section class="section" style="padding-top: 105px; height: 100vh;">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-8 bg-light px-0">
                    <h2 class="text-dark text-uppercase text-center pt-2">Transactions</h2>
                    @if (transactionHistoryDetail.length > 0) {
                    @for (item of transactionHistoryDetail; track $index) {
                    <div class="col-12 d-flex flex-wrap justify-content-between align-items-center py-2"
                        style="box-shadow: 0px 2px 2px lightcoral;">
                        <div class="col-2 text-center">
                            @if(item.receiver_pic) {
                            <img src="{{item.receiver_pic}}" alt="User Image" class="rounded-circle"
                                style="width: 50px; object-fit: cover;">
                            } @else {
                            <img src="../../../assets/user-139.png" alt="User Image" class="rounded-circle"
                                style="width: 50px; object-fit: cover;">
                            }
                        </div>
                        <div class="col-8">
                            <p class="fw-semibold mb-0 text-dark">
                                {{item.transaction_type}}
                            </p>
                            <p class="mb-0 text-dark">
                                {{item.transaction_status}}
                            </p>
                        </div>
                        <div class="col-2">
                            <button type="button" class="btn" [ngClass]="{
                                'btn-success': item.transaction_status === 'Success',
                                'btn-warning': item.transaction_status === 'Pending',
                                'btn-danger': item.transaction_status === 'Cancelled'
                              }">{{item.opp_user_currency}} {{item.amount}}</button>
                        </div>
                    </div>
                    }
                    } @else {
                        <p class="text-dark text-center">No Transaction Data</p>
                    }
                </div>
            </div>
        </div>
    </section>
    <!-- History Section End -->

    <!-- Footer Start -->
    <app-footer></app-footer>
    <!-- Footer End -->
</section>