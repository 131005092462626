<section style="background-color: #15202b;">

    <!-- Nav Bar Start -->
    <app-nav-bar></app-nav-bar>
    <!-- Nav Bar End -->

    <!-- Transaction Limit Section Start -->
    <section class="section" style="padding-top: 105px; height: 100vh;">
        <div class="container">
            <div class="row justify-content-center">
                <h1 class="text-light text-center">TRANSAFER LIMITS</h1>
                <div class="col-12 col-md-6 mx-auto card rounded-10 border-0 shadow-lg p-4"
                    style="background: #FFEBEC;">
                    <div class="col-12 card-body p-0 py-4 d-flex flex-wrap justify-content-center align-items-center">
                        <div class="col-12 col-md-8 d-flex flex-wrap flex-column justify-content-center align-items-center py-3 rounded border-bottom mb-2">
                            <h4>SENDING LIMIT</h4>
                            <h4 class="mb-0">{{transactionLimit.user_currency}} {{transactionLimit.tranfer_limit}}</h4>
                        </div>
                        <div class="col-12 col-md-8 d-flex flex-wrap flex-column justify-content-center align-items-center py-3 rounded border-bottom mb-2">
                            <h4>REMAINING LIMIT</h4>
                            <h4 class="mb-0">{{transactionLimit.user_currency}} {{transactionLimit.remaining_limit}}</h4>
                        </div>
                        <div class="col-12 col-md-8 d-flex flex-wrap flex-column justify-content-center align-items-center py-3 rounded border-bottom mb-2">
                            <h4>RECEIVING LIMIT</h4>
                            <h4 class="mb-0">UNLIMITED</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Transaction Limit Section End -->

    <!-- Footer Start -->
    <app-footer></app-footer>
    <!-- Footer End -->
</section>