import { Component } from '@angular/core';
import { FooterComponent } from '../nav/footer/footer.component';
import { NavBarComponent } from '../nav/nav-bar/nav-bar.component';

@Component({
  selector: 'app-account-support',
  standalone: true,
  imports: [NavBarComponent, FooterComponent],
  templateUrl: './account-support.component.html',
  styleUrl: './account-support.component.css'
})
export class AccountSupportComponent {

}
