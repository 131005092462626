<section style="background-color: #15202b;">

    <!-- Nav Bar Start -->
    <app-nav-bar></app-nav-bar>
    <!-- Nav Bar End -->

    <!-- Add Money Section Start -->
    @if(cashCardShow == false && bankCardShow == false) {
    <section class="section" style="padding-top: 125px; min-height: 100vh;">
        <div class="container">
            <div class="row">
                <h1 class="text-light text-center">SELECT DEPOSIT | WITHDRAW OPTION</h1>
                <div class="col-12 col-md-5 mx-auto card rounded-10 border-0 shadow-lg p-4 text-center"
                    style="background: #FFEBEC;">
                    <div class="col-12 card-body p-4 text-center">
                        <div class="forn-group row">
                            <div class="form-check form-check-inline m-0 text-start">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" name="radio" value="bank" checked
                                        (change)="radioChange($event)">
                                    <h2 class="mb-0">Fast Bank Account Cash-out</h2>
                                </label>
                                <p style="font-size: 13px;">(Funds payout within 24 hours or less and fees may apply)
                                </p>
                            </div>
                            <div class="form-check form-check-inline m-0 text-start">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" name="radio" value="cash"
                                        (change)="radioChange($event)">
                                    <h2 class="mb-0">Cash Pick up</h2>
                                </label>
                                <p style="font-size: 13px;">(Money ready within 24 hours and fees may apply. Gov't ID
                                    will be required during pick up)</p>
                            </div>
                        </div>
                        <div class="col-12 text-center">
                            <button type="submit" class="col-12 btn btn-danger py-2" (click)="goToNext()">Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    }
    <!-- Add Money Section End -->

    @if (bankCardShow) {
    <section class="section" style="padding-top: 125px; min-height: 100vh;">
        <div class="container">
            <div class="row">
                <h1 class="text-light text-center">RECEIVER INFORMATION</h1>
                <div class="col-12 col-md-4 mx-auto card rounded-10 border-0 shadow-lg p-4 text-start"
                    style="background: #FFEBEC;">
                    <p class="text-dark">Information provided on here should match the receiver government ID details.
                    </p>
                    <div class="col-12 card-body p-0 text-center">
                        <div class="d-flex flex-wrap justify-content-center">
                            <div class="form-group py-3 text-start col-12">
                                <input type="text" class="form-control border-0 box-shadow-class" placeholder="Enter First Name">
                            </div>
                            <div class="form-group py-3 text-start col-12">
                                <input type="text" class="form-control border-0 box-shadow-class"
                                    placeholder="Enter Middle Name(Optional)">
                            </div>
                            <div class="form-group py-3 text-start col-12">
                                <input type="text" class="form-control border-0 box-shadow-class" placeholder="Enter Last Name">
                            </div>
                            <div class="form-group py-3 text-start col-12">
                                <div class="col-12 d-flex flex-wrap p-0">
                                    <div class="col-2 p-0 mb-3">
                                        <p-dropdown appendTo="body" [options]="countryCodes" optionValue="code"
                                            optionLabel="name" placeholder="Country Code" [filter]="true"
                                            styleClass="col-12 border-0 box-shadow-class">
                                        </p-dropdown>
                                    </div>
                                    <div class="col-10 p-0">
                                        <input type="text" class="form-control border-0 box-shadow-class" id="exampleInputNumber"
                                            aria-describedby="emailHelp" placeholder="Enter Your Phone Number" minlength="10"
                                            maxlength="10">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 text-center">
                            <button type="submit" class="col-12 btn btn-danger py-2 mb-2 me-3" (click)="cancle()">Cancle</button>
                            <button type="submit" class="col-12 btn btn-danger mb-4 py-2" (click)="next()">Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    }

    @if (cashCardShow) {
        <section class="section" style="padding-top: 125px; min-height: 100vh;">
            <div class="container">
                <div class="row">
                    <h1 class="text-light text-center">RECEIVER INFORMATION</h1>
                    <div class="col-12 col-md-4 mx-auto card rounded-10 border-0 shadow-lg p-4 text-start"
                        style="background: #FFEBEC;">
                        <p class="text-dark">Information provided on here should match the receiver government ID details.
                        </p>
                        <div class="col-12 card-body p-0 text-center">
                            <div class="d-flex flex-wrap justify-content-center">
                                <div class="form-group py-3 text-start col-12">
                                    <input type="text" class="form-control border-0 box-shadow-class" placeholder="Enter First Name">
                                </div>
                                <div class="form-group py-3 text-start col-12">
                                    <input type="text" class="form-control border-0 box-shadow-class"
                                        placeholder="Enter Middle Name(Optional)">
                                </div>
                                <div class="form-group py-3 text-start col-12">
                                    <input type="text" class="form-control border-0 box-shadow-class" placeholder="Enter Last Name">
                                </div>
                                <div class="form-group py-3 text-start col-12">
                                    <div class="col-12 d-flex flex-wrap p-0">
                                        <div class="col-2 p-0 mb-3">
                                            <p-dropdown appendTo="body" [options]="countryCodes" optionValue="code"
                                                optionLabel="name" placeholder="Country Code" [filter]="true"
                                                styleClass="col-12 border-0 box-shadow-class">
                                            </p-dropdown>
                                        </div>
                                        <div class="col-10 p-0">
                                            <input type="text" class="form-control border-0 box-shadow-class" id="exampleInputNumber"
                                                aria-describedby="emailHelp" placeholder="Enter Your Phone Number" minlength="10"
                                                maxlength="10">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-center">
                                <button type="submit" class="col-12 btn btn-danger py-2 mb-2 me-3" (click)="cancle()">Cancle</button>
                                <button type="submit" class="col-12 btn btn-danger mb-4 py-2" (click)="next()">Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        }

    <!-- Footer Start -->
    <app-footer></app-footer>
    <!-- Footer End -->
</section>