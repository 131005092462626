import { Component } from '@angular/core';
import { FooterComponent } from '../nav/footer/footer.component';
import { NavBarComponent } from '../nav/nav-bar/nav-bar.component';

@Component({
  selector: 'app-request-money',
  standalone: true,
  imports: [NavBarComponent, FooterComponent],
  templateUrl: './request-money.component.html',
  styleUrl: './request-money.component.css'
})
export class RequestMoneyComponent {
  search = false;
  searchClick() {
    this.search = true;
  }

  cancle() {
    this.search = false;
  }

  sendRequest() {
    this.search = false;
  }
}
