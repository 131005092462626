import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, MinLengthValidator, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { Router, RouterModule } from '@angular/router';
import { DropdownModule } from 'primeng/dropdown';
import { ApiService } from '../../service/api.service';
import { first } from 'rxjs';
import { FirebaseService } from '../../service/firebase.service';
import { HttpHeaders } from '@angular/common/http';
import { GoogleApiService, Maps } from '../../service/google/google-api.service';

@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [MatSelectModule, DropdownModule, ReactiveFormsModule, CommonModule, RouterModule],
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.css'
})

export class SignUpComponent implements OnInit, AfterViewInit {

  @ViewChild('search')
  public searchElementRef!: ElementRef;

  mobileVerifyForm: FormGroup;
  OTPVerifyForm: FormGroup;
  personalDetailForm: FormGroup;
  signUpForm: FormGroup;
  SecurityPINForm: FormGroup;

  constructor(private service: ApiService, public router: Router, private formBuilder: FormBuilder, private authService: FirebaseService, public apiService: GoogleApiService, private cdRef: ChangeDetectorRef) {
    this.mobileVerifyForm = this.formBuilder.group({
      mobileNo: ['', Validators.required],
      countryISO: ['', Validators.required]
    });
    this.OTPVerifyForm = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.minLength(6)]]
    });
    apiService.api.then((maps) => {
      this.initAutocomplete(maps);
    });
    this.signUpForm = this.formBuilder.group({
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      lolliname: ['LC', [Validators.required, Validators.minLength(6), Validators.maxLength(12)]],
      email: ['', Validators.required],
      password: ['', Validators.required],
      cpassword: ['', Validators.required]
    });
    this.personalDetailForm = this.formBuilder.group({
      dob: [''],
      address: [''],
      city: [''],
      state: [''],
      zip: [''],
      country: [''],
    });
    this.SecurityPINForm = this.formBuilder.group({
      securityPIN: ['', [Validators.required, Validators.minLength(6)]],
      csecurityPIN: ['', Validators.required]
    })
  }

  countryCodes: any = [];

  ngAfterViewInit() {
    this.apiService.api.then((maps) => {
      this.initAutocomplete(maps);
    });
  }

  ngOnInit(): void {
    this.service.getCountriesCode().pipe(first()).subscribe((response: any) => {
      this.countryCodes = response;
    });
  }

  verificationId: any;
  otp: any;
  mobileVerifed = false;
  countryCode: any;
  sendOtp() {
    if (this.mobileVerifyForm.valid) {
      console.log('Form submitted:', this.mobileVerifyForm.value);
      this.countryCode = this.countryCodes.find((country: any) => country.code == this.mobileVerifyForm.get('countryISO')?.value).dial_code;
      console.log(this.countryCode);
      let mobileNumber = this.countryCode + this.mobileVerifyForm.value.mobileNo;
      this.authService.sendOtp(mobileNumber).subscribe(
        (result: any) => {
          this.verificationId = result.verificationId;
        },
        (error: any) => {
          console.error('Error sending OTP:', error);
        }
      );
    } else {
      console.log("Form is not valid");
      if (this.mobileVerifyForm.value.mobileNo === null || this.mobileVerifyForm.value.mobileNo === '') {
        return this.service.notify.showSuccess('Mobile Number is required Property');
      }
    }
  }

  session: any;
  verifyOtp() {
    if (this.OTPVerifyForm.valid) {
      this.otp = this.OTPVerifyForm.value.otp;
      this.authService.verifyOtp(this.verificationId, this.otp).subscribe(
        (result: any) => {
          if (result) {
            this.mobileVerifed = true;
            let update = '1';
            const formData = new FormData();
            formData.append('country_code', this.countryCode.replace('+', ''));
            formData.append('mobile', this.mobileVerifyForm.get('mobileNo')?.value);
            formData.append('device_type', '3');
            formData.append('country_iso', this.mobileVerifyForm.get('countryISO')?.value);
            formData.append('updte', update);

            this.service.signUp(formData).pipe(first()).subscribe((response: any) => {
              if (response) {
                console.log("Sign Up Detail Response --", response);
                this.session = response.session;
                this.personalDetail = false;
              }
              this.service.notify.showSuccess(response.error_msg);
            });
          }
        },
        (error: any) => {
          console.error('Error verifying OTP:', error);
          this.service.notify.showSuccess('Please Enter Valid OTP!!!');
        }
      );
    } else {
      // return this.service.notify.showSuccess('Please Enter OTP!!!');
      if (this.OTPVerifyForm.controls['otp'].errors?.['required']) {
        return this.service.notify.showSuccess('Please Enter OTP');
      }
      if (this.OTPVerifyForm.controls['otp'].errors?.['minlength']) {
        return this.service.notify.showSuccess('OTP must be at least 6 characters long');
      }
    }
  }

  personalDetail = false;
  signUp() {
    if (this.signUpForm.valid) {
      if (this.signUpForm.value.password !== this.signUpForm.value.cpassword) {
        return this.service.notify.showSuccess("Password and Confirm Password not Match!!!");
      }

      let update = '1';
      const formData = new FormData();
      formData.append('updte', update);
      formData.append('session', this.session);
      formData.append('user_name', this.signUpForm.get('fname')?.value + ' ' + this.signUpForm.get('lname')?.value);
      formData.append('lolli_name', this.signUpForm.get('lolliname')?.value);
      formData.append('user_email', this.signUpForm.get('email')?.value);
      formData.append('user_password', this.signUpForm.get('password')?.value);

      this.service.personalDetail(formData).pipe(first()).subscribe((response: any) => {
        if (response) {
          if (response.error == 0) {
          console.log("Personal Detail Response --", response);
          this.personalDetail = true;
          } else {
            return this.service.notify.showSuccess(response.error_msg);
          }
          this.service.notify.showSuccess(response.error_msg);
        }
      });

    } else {
      console.log("Form is not Valid!!!");
      if (this.signUpForm.value.fname === null || this.signUpForm.value.fname === '') {
        return this.service.notify.showSuccess('First Name is required Property');
      }
      if (this.signUpForm.value.lname === null || this.signUpForm.value.lname === '') {
        return this.service.notify.showSuccess('Last Name is required Property');
      }
      if (this.signUpForm.controls['lolliname'].errors?.['required']) {
        return this.service.notify.showSuccess('Lolli Cash Name is required Property');
      }
      if (this.signUpForm.controls['lolliname'].errors?.['minlength']) {
        return this.service.notify.showSuccess('Lolli Cash Name must be at least   6 characters long');
      }
      if (this.signUpForm.controls['lolliname'].errors?.['maxlength']) {
        return this.service.notify.showSuccess('Lolli Cash Name cannot be more than   12 characters long');
      }
      if (this.signUpForm.value.email === null || this.signUpForm.value.email === '') {
        return this.service.notify.showSuccess('Email is required Property');
      }
      if (this.signUpForm.value.password === null || this.signUpForm.value.password === '') {
        return this.service.notify.showSuccess('Password is required Property');
      }
      if (this.signUpForm.value.cpassword === null || this.signUpForm.value.cpassword === '') {
        return this.service.notify.showSuccess('Confirm Password is required Property');
      }
    }
  }

  onAddressInputChange(event: Event) {
    this.apiService.api.then((maps) => {
      this.initAutocomplete(maps);
      //this.initMap(maps);
    });
    console.log('Address input changed:', (event.target as HTMLInputElement).value);
  }

  latitude: any;
  longitude: any;
  address: any;
  country: any;
  city: any;
  countryId: any;
  stateShortName: any;
  initAutocomplete(maps: Maps) {
    console.log("its in autoComplete");

    let autocomplete = new maps.places.Autocomplete(
      this.searchElementRef?.nativeElement
    );
    console.log("autocomplete --", autocomplete);

    autocomplete.addListener('place_changed', () => {
      var place = autocomplete.getPlace();
      console.log("Place --", place);
      if (place && place.address_components && place.geometry) {
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();

        this.address = place.formatted_address;
        this.personalDetailForm.get('address')?.setValue(this.address);
        console.log("Address --", this.address);

        const stateComponent = place.address_components.find(component =>
          component.types.includes('administrative_area_level_1')
        );
        this.stateShortName = stateComponent ? stateComponent.short_name : null;
        console.log("State Short Name --", this.stateShortName);

        const zipCodeComponent = place.address_components.find(component =>
          component.types.includes('postal_code')
        );
        const zipCode = zipCodeComponent ? zipCodeComponent.long_name : null;
        console.log("Zip Code --", zipCode);
        this.personalDetailForm.get('zip')?.setValue(zipCode);

        const state = stateComponent ? stateComponent.long_name : null;
        console.log("State --", state);
        this.personalDetailForm.get('state')?.setValue(state);

        this.country = this.getAddressComponent(place.address_components, 'country');
        console.log("Country --", this.country);

        let countryCodeObj = this.countryCodes.find((x: any) => x.name == this.country);

        console.log(countryCodeObj);
        this.countryCode = countryCodeObj.name;
        this.personalDetailForm.get('country')?.setValue(this.countryCode);

        this.city = this.getAddressComponent(place.address_components, 'locality') || this.getAddressComponent(place.address_components, 'administrative_area_level_1');
        console.log(this.city);
        if (this.city) {
          this.personalDetailForm.get('city')?.setValue(this.city);
        }
        console.log("Sign Up FOrm --", this.personalDetailForm.value);

      }
    });

  }
  private getAddressComponent(components: google.maps.GeocoderAddressComponent[], type: string): string | null {
    const component = components.find(c => c.types.includes(type));
    return component ? component.long_name : null;
  }

  pin = false;
  updateProfile() {
    if (this.personalDetailForm.valid) {
      let update = '1';
      const formData = new FormData();
      formData.append('user_email', this.signUpForm.get('email')?.value);
      // formData.append('is_new_register', '2');
      formData.append('session', this.session);
      formData.append('user_address', this.personalDetailForm.get('address')?.value);
      formData.append('latitude', this.latitude);
      formData.append('last_name', this.signUpForm.get('lname')?.value);
      formData.append('user_city', this.personalDetailForm.get('city')?.value);
      formData.append('middle_name', '');
      formData.append('zip_code', this.personalDetailForm.get('zip')?.value);
      formData.append('user_state', this.personalDetailForm.get('state')?.value);
      formData.append('ssn', '');
      formData.append('country_code', this.countryCode);
      // formData.append('career_id', '7');
      formData.append('state_sort_code', this.stateShortName);
      formData.append('user_mobile', this.mobileVerifyForm.get('mobileNo')?.value);
      formData.append('dob', this.personalDetailForm.get('dob')?.value);
      formData.append('country_name', this.personalDetailForm.get('country')?.value);
      formData.append('first_name', this.signUpForm.get('fname')?.value);
      formData.append('updte', update);
      formData.append('longitude', this.longitude);

      console.log(formData);


      this.service.personalInfo(formData).pipe(first()).subscribe((response: any) => {
        if (response) {
          if (response.error == 0) {
            this.pin = true;
          } else {
            this.service.notify.showSuccess(response.error_msg);
            return;
          }
        }
        this.service.notify.showSuccess(response.error_msg);
      });
    } else {
      console.log("Form is not Valid!!!");
    }
  }

  setSecurityPIN() {
    if (this.SecurityPINForm.valid) {
      if (this.SecurityPINForm.value.securityPIN !== this.SecurityPINForm.value.csecurityPIN) {
        return this.service.notify.showSuccess('Security PIN and Confirm Security PIN not Match!!!');
      }
      let update = '1';
      const formData = new FormData();
      formData.append('login_pin', this.SecurityPINForm.get('securityPIN')?.value);
      formData.append('session', this.session);
      formData.append('update', update);

      this.service.loginPIN(formData).pipe(first()).subscribe((response: any) => {
        if (response) {
          console.log(response);
          this.router.navigate(['/dashboard']);
        }
        this.service.notify.showSuccess(response.error_msg);
      });

    } else {
      console.log("Form is not Valid!!!");
      if (this.SecurityPINForm.controls['securityPIN'].errors?.['required']) {
        return this.service.notify.showSuccess('Please Enter Security PIN');
      }
      if (this.OTPVerifyForm.controls['otp'].errors?.['minlength']) {
        return this.service.notify.showSuccess('OTP must be at least 6 characters long');
      }
      if (this.SecurityPINForm.controls['csecurityPIN'].errors?.['required']) {
        return this.service.notify.showSuccess('Please Enter Confirm Security PIN');
      }
    }
  }

}
