import { Component, OnInit } from '@angular/core';
import { NavBarComponent } from '../nav/nav-bar/nav-bar.component';
import { FooterComponent } from '../nav/footer/footer.component';
import { ApiService } from '../../service/api.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [NavBarComponent, FooterComponent],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent implements OnInit {

  constructor(private service: ApiService) { }

  userDetail: any;
  ngOnInit(): void {
    if (typeof localStorage !== 'undefined') {
      this.userDetail = localStorage.getItem('user');
      if (this.userDetail) {
        this.userDetail = JSON.parse(this.userDetail);
      }
    } else {
      console.log('localStorage is not available.');
    }
    this.getProfile();
  }
  
  userProfileData: any;
  getProfile() {
    const formData = new FormData();
    if (this.userDetail) {
      formData.append('session', this.userDetail.session);
      formData.append('updte', '1');
      this.service.get_profile(formData).pipe(first()).subscribe((response: any) => {
        if (response) {
          console.log(response);
          this.userProfileData = response.data;
        }
      });
    }
  }

}
