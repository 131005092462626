<footer class="py-2" style="background-color: #22303c;">
    <div class="container">
        <div class="row">
            <div class="d-flex flex-wrap justify-content-between align-items-center">
                <div class="col-4">
                    <p class="text-light mb-0">© LolliCash 2024. All rights reserved</p>
                </div>
                <div class="col-8 text-end">
                    <p class="text-light mb-0">
                        <a href="/transaction_limit" class="text-light text-decoration-none">Transaction Limits</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>