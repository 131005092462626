import { Component, OnInit } from '@angular/core';
import { FooterComponent } from '../nav/footer/footer.component';
import { NavBarComponent } from '../nav/nav-bar/nav-bar.component';
import { first } from 'rxjs';
import { ApiService } from '../../service/api.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-all-transaction',
  standalone: true,
  imports: [NavBarComponent, FooterComponent, CommonModule],
  templateUrl: './all-transaction.component.html',
  styleUrl: './all-transaction.component.css'
})
export class AllTransactionComponent implements OnInit {

  constructor(private service: ApiService) { }

  userDetail: any;
  ngOnInit(): void {
    if (typeof localStorage !== 'undefined') {
      this.userDetail = localStorage.getItem('user');
      if (this.userDetail) {
        this.userDetail = JSON.parse(this.userDetail);
      }
    } else {
      console.log('localStorage is not available.');
    }
    this.getTransactionHistory();
  }


  transactionHistoryDetail: any = [];
  getTransactionHistory() {
    const formData = new FormData();
    if (this.userDetail) {
      formData.append('session', this.userDetail.session);
      formData.append('transaction_type', 'All');
      formData.append('updte', '1');
      this.service.getTransactionHistory(formData).pipe(first()).subscribe((response: any) => {
        if (response) {
          console.log(response);
          this.transactionHistoryDetail = response.data;
        }
      });
    }
  }
}
