import { CommonModule } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DropdownModule } from "primeng/dropdown";
import { ApiService } from "../../../service/api.service";
import { first } from "rxjs";
import {
  GoogleApiService,
  Maps,
} from "../../../service/google/google-api.service";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedDataService } from "../../../service/shared/shared-data.service";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-event-ticket",
  standalone: true,
  imports: [CommonModule, DropdownModule, ReactiveFormsModule],
  templateUrl: "./event-ticket.component.html",
  styleUrl: "./event-ticket.component.css",
})
export class EventTicketComponent implements OnInit {
  eventTicketForm: FormGroup;

  constructor(
    private service: ApiService,
    public locationService: GoogleApiService,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private sharedDataService: SharedDataService
  ) {
    locationService.api.then((maps) => {
      this.initAutocomplete(maps);
    });
    this.eventTicketForm = this.formBuilder.group({
      first_name: ["", Validators.required],
      last_name: ["", Validators.required],
      // middle_name: [''],
      user_mobile: ["", [Validators.required, this.phoneNumberValidator()]],
      user_email: ["", [Validators.required, this.emailValidator()]],
      country_code: ["", Validators.required],
      address: ["", Validators.required],
      user_city: ["", Validators.required],
      user_state: ["", Validators.required],
      postal_code: ["", Validators.required],
      state_sort_code: [""],
      country_iso: [""],
      country: ["", Validators.required],
    });
  }
  dataSubscription: any;


  phoneNumberValidator() {
    return (control: { value: any }) => {
      const phoneNumber = control.value;
      if (!phoneNumber || /[0-9]{10,}/.test(phoneNumber)) {
        // if (!phoneNumber || /^[0-9]+$/.test(phoneNumber)) {
        return null; // Validation passes
      } else {
        return { invalidPhoneNumber: true }; // Validation fails
      }
    };
  }
  emailValidator() {
    return (control: { value: any }): { [key: string]: any } | null => {
      const email: string = control.value;

      // Regular expression for email validation
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!email || emailRegex.test(email)) {
        return null; // Validation passes
      } else {
        return { invalidEmail: true }; // Validation fails
      }
    };
  }
  countryCodes: any = [];
  userId: any;
  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get("user_id");
    console.log("user iD", this.userId);

    this.dataSubscription = this.sharedDataService.data$.subscribe((data) => {
      console.log("DATA DATA", data);

      // this.ticketUserData = data;
      // this.buyTicketId = data?.ticket_buy_id;
    });

    this.service
      .getCountriesCode()
      .pipe(first())
      .subscribe((response: any) => {
        this.countryCodes = response;
      });
  }
  urldata : any = '';
  savePersonalInfo() {
    this.dataSubscription = this.sharedDataService.data$.subscribe((data) => {
      // console.log(" save DATA 123", data);

      this.urldata = data;

      // this.ticketUserData = data;
      // this.buyTicketId = data?.ticket_buy_id;
    });
    console.log(this.eventTicketForm.value);
    if (this.eventTicketForm.valid) {
      let countryCode = this.countryCodes.find(
        (country: any) =>
          country.code == this.eventTicketForm.get("country_code")?.value
      ).dial_code;

      const formData = new FormData();
      formData.append(
        "first_name",
        this.eventTicketForm.get("first_name")?.value
      );
      // formData.append('middle_name', this.eventTicketForm.get('middle_name')?.value);
      formData.append(
        "last_name",
        this.eventTicketForm.get("last_name")?.value
      );
      formData.append("country_code", countryCode.replace("+", ""));
      formData.append(
        "user_mobile",
        this.eventTicketForm.get("user_mobile")?.value
      );
      formData.append(
        "user_email",
        this.eventTicketForm.get("user_email")?.value
      );
      formData.append("address", this.eventTicketForm.get("address")?.value);
      formData.append(
        "user_city",
        this.eventTicketForm.get("user_city")?.value
      );
      formData.append(
        "user_state",
        this.eventTicketForm.get("user_state")?.value
      );
      formData.append(
        "postal_code",
        this.eventTicketForm.get("postal_code")?.value
      );
      formData.append(
        "state_sort_code",
        this.eventTicketForm.get("state_sort_code")?.value
      );
      formData.append(
        "country_iso",
        this.eventTicketForm.get("country_iso")?.value
      );
      formData.append("country", this.eventTicketForm.get("country")?.value);
      formData.append("platform", environment.platform);
      formData.append("guest_user_id", this.userId);

      this.service
        .stripe_event_sender(formData)
        .pipe(first())
        .subscribe((response: any) => {
          console.log(response);
          if (response) {

            console.log("this.urldata",this.urldata);
            
            window.location.href = this.urldata;
            // this.sharedDataService.setData(response);
            // this.router.navigate(['ticket_list']);
          }
        });
    } else {
      if (
        this.eventTicketForm.value.first_name === null ||
        this.eventTicketForm.value.first_name === ""
      ) {
        return this.service.notify.showSuccess(
          "First Name is required Property"
        );
      }
      if (
        this.eventTicketForm.value.last_name === null ||
        this.eventTicketForm.value.last_name === ""
      ) {
        return this.service.notify.showSuccess(
          "Last Name is required Property"
        );
      }
      if (
        this.eventTicketForm.value.country_code === null ||
        this.eventTicketForm.value.country_code === ""
      ) {
        return this.service.notify.showSuccess(
          "Country Code is required Property"
        );
      }
      if (
        this.eventTicketForm.value.user_mobile === null ||
        this.eventTicketForm.value.user_mobile === ""
      ) {
        return this.service.notify.showSuccess(
          "Mobile Number is required Property"
        );
      }
      if (
        this.eventTicketForm.value.user_email === null ||
        this.eventTicketForm.value.user_email === ""
      ) {
        return this.service.notify.showSuccess("Email is required Property");
      }
      if (
        this.eventTicketForm.value.address === null ||
        this.eventTicketForm.value.address === ""
      ) {
        return this.service.notify.showSuccess("Address is required Property");
      }
      if (
        this.eventTicketForm.value.user_city === null ||
        this.eventTicketForm.value.user_city === ""
      ) {
        return this.service.notify.showSuccess("City is required Property");
      }
      if (
        this.eventTicketForm.value.user_state === null ||
        this.eventTicketForm.value.user_state === ""
      ) {
        return this.service.notify.showSuccess("State is required Property");
      }
      if (
        this.eventTicketForm.value.postal_code === null ||
        this.eventTicketForm.value.postal_code === ""
      ) {
        return this.service.notify.showSuccess("Zip Code is required Property");
      }
      if (
        this.eventTicketForm.value.country === null ||
        this.eventTicketForm.value.country === ""
      ) {
        return this.service.notify.showSuccess("Country is required Property");
      }
    }
  }

  onAddressInputChange(event: Event) {
    this.locationService.api.then((maps) => {
      this.initAutocomplete(maps);
    });
    console.log(
      "Address input changed:",
      (event.target as HTMLInputElement).value
    );
  }

  @ViewChild("search")
  public searchElementRef!: ElementRef;
  latitude: any;
  longitude: any;
  address: any;
  country: any;
  countryCode: any;
  city: any;
  countryId: any;
  state: any;

  initAutocomplete(maps: Maps) {
    console.log("its in autoComplete");

    let autocomplete = new maps.places.Autocomplete(
      this.searchElementRef?.nativeElement
    );
    autocomplete.addListener("place_changed", () => {
      var place = autocomplete.getPlace();
      console.log(place);
      if (place && place.address_components && place.geometry) {
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();

        this.address = place.formatted_address;

        this.eventTicketForm.get("address")?.setValue(this.address);
        this.cdr.detectChanges();

        console.log("Address --", this.address);

        this.country = this.getAddressComponent(
          place.address_components,
          "country"
        );
        console.log("Country --", this.country);
        this.eventTicketForm.get("country")?.setValue(this.country);
        this.cdr.detectChanges();

        let countryCodeObj = this.countryCodes.find(
          (x: any) => x.name == this.country
        );

        console.log(countryCodeObj);
        this.countryCode = countryCodeObj.code;
        this.eventTicketForm
          .get("country_iso")
          ?.setValue(this.countryCode.toString());

        // let countryIdObj = this.countriesData.find((x: any) => x.CountryName === this.country);

        // console.log(countryIdObj);
        // this.countryId = countryIdObj.Id;
        // this.eventTicketForm.get('countryId')?.patchValue(countryIdObj.Id);
        this.cdr.detectChanges();
        console.log("---", this.eventTicketForm.value.country_iso);

        this.city =
          this.getAddressComponent(place.address_components, "locality") ||
          this.getAddressComponent(
            place.address_components,
            "administrative_area_level_3"
          );
        this.state = this.getAddressComponent(
          place.address_components,
          "administrative_area_level_1"
        );

        console.log(this.city);

        if (this.city) {
          this.eventTicketForm.get("user_city")?.setValue(this.city);
          this.cdr.detectChanges();
        }
        if (this.state) {
          this.eventTicketForm.get("user_state")?.setValue(this.state);
          this.cdr.detectChanges();
        }

        const stateShortName = this.getAddressComponent(
          place.address_components,
          "administrative_area_level_1"
        );
        console.log(stateShortName);

        if (stateShortName) {
          this.eventTicketForm.get("state_sort_code")?.setValue(stateShortName);
        }

        const postalCode = this.getAddressComponent(
          place.address_components,
          "postal_code"
        );
        console.log("Postal Code:", postalCode);
        if (postalCode) {
          this.eventTicketForm.get("postal_code")?.setValue(postalCode);
          this.cdr.detectChanges();
        }

        console.log(this.eventTicketForm.value);
      }
      // this.ngZone.run(() => {
      // });
    });
  }
  private getAddressComponent(
    components: google.maps.GeocoderAddressComponent[],
    type: string
  ): string | null {
    const component = components.find((c) => c.types.includes(type));
    return component ? component.long_name : null;
  }

  goBack() {
    window.history.back();
  }
}
