<section style="background-color: #15202b;">

    <!-- Nav Bar Start -->
    <app-nav-bar></app-nav-bar>
    <!-- Nav Bar End -->

    <!-- Profile Section Start -->
    <section class="section" style="padding-top: 105px; min-height: 100vh;">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-8">
                    <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="pills-account-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-account" type="button" role="tab" aria-controls="pills-account"
                                aria-selected="true">Account</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-security-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-security" type="button" role="tab" aria-controls="pills-security"
                                aria-selected="false">Account Secuity</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-notification-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-notification" type="button" role="tab"
                                aria-controls="pills-notification" aria-selected="false">Notification</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-account" role="tabpanel"
                            aria-labelledby="pills-account-tab">
                            <div class="card rounded-10 border-0 shadow-lg p-4">
                                <div class="d-flex justify-content-around mb-4 card-img overflow-hidden">
                                    <img class="card-img rounded-circle "
                                        src="https://opensource.com/sites/default/files/delisa_alexander_headshot.jpg"
                                        style="width: 150px; height: 150px;" alt="Wallet Card image">
                                    <img src="https://media.istockphoto.com/id/828088276/vector/qr-code-illustration.jpg?s=612x612&w=0&k=20&c=FnA7agr57XpFi081ZT5sEmxhLytMBlK4vzdQxt8A70M="
                                        style="width: 150px; height: 150px;" alt="Wallet Card image">
                                </div>
                                <div class="col-12 card-body p-0">
                                    <div class="form-row d-flex flex-wrap justify-content-between align-items-center">
                                        <div class="form-group row col-12 col-md-6">
                                            <label class="col-form-label">Profile Photo:</label>
                                            <div class="input-group">
                                                <input type="file" class="form-control"
                                                    placeholder="Choose Profile Photo">
                                            </div>
                                        </div>
                                        <div class="form-group row col-12 col-md-6">
                                            <label class="col-form-label">LolliCash Name:</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" value="{{userProfileData?.lolli_name}}"
                                                    placeholder="Enter your LolliCash Name" [readOnly]="true">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row d-flex flex-wrap justify-content-between align-items-center">
                                        <div class="form-group row col-12 col-md-6">
                                            <label class="col-form-label">Mobile Number:</label>
                                            <div class="input-group">
                                                <input type="number" class="form-control"
                                                    placeholder="Enter your Mobile Number" maxlength="10" value="{{userProfileData?.user_mobile}}">
                                            </div>
                                        </div>
                                        <div class="form-group row col-12 col-md-6">
                                            <label class="col-form-label">Email:</label>
                                            <div class="input-group">
                                                <input type="email" class="form-control" placeholder="Enter your Email" value="{{userProfileData?.user_email}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row d-flex flex-wrap justify-content-between align-items-center">
                                        <div class="form-group row col-12 col-md-6">
                                            <label class="col-form-label">Date Of Birth:</label>
                                            <div class="input-group">
                                                <input type="date" class="form-control" placeholder="Choose DOB" value="{{userProfileData?.dob}}">
                                            </div>
                                        </div>
                                        <div class="form-group row col-12 col-md-6">
                                            <label class="col-form-label">Address:</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                    placeholder="Enter your Address" value="{{userProfileData?.user_address}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row d-flex flex-wrap justify-content-between align-items-center">
                                        <div class="form-group row col-12 col-md-6">
                                            <label class="col-form-label">City:</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" placeholder="Enter your City" value="{{userProfileData?.user_city}}">
                                            </div>
                                        </div>
                                        <div class="form-group row col-12 col-md-6">
                                            <label class="col-form-label">State:</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" placeholder="Enter your State" value="{{userProfileData?.user_state}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row d-flex flex-wrap justify-content-between align-items-center">
                                        <div class="form-group row col-12 col-md-6">
                                            <label class="col-form-label">Pin Code:</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                    placeholder="Enter your Pin Code" maxlength="6" value="{{userProfileData?.zip_code}}">
                                            </div>
                                        </div>
                                        <div class="form-group row col-12 col-md-6">
                                            <label class="col-form-label">Country:</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                    placeholder="Enter your Country" value="{{userProfileData?.country_name}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-wrap justify-content-between align-items-center mt-4">
                                        <p class="text-danger mb-0">Deactive Account</p>
                                        <button type="submit" class="btn btn-danger">Update Profile</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade bg-light" id="pills-security" role="tabpanel"
                            aria-labelledby="pills-security-tab">
                            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center py-2 ps-2"
                                style="box-shadow: 0px 2px 2px lightcoral;">
                                <div class="col-10">
                                    <p class="fw-semibold mb-0 text-dark">
                                        Two-Factor Authentication
                                    </p>
                                </div>
                                <div class="col-2 text-end">
                                    <div class="form-check form-switch form-switch-md">
                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center py-2 ps-2"
                                style="box-shadow: 0px 2px 2px lightcoral;">
                                <div class="col-10">
                                    <p class="fw-semibold mb-0 text-dark">
                                        Change Security Pin
                                    </p>
                                </div>
                                <div class="col-2 text-end">
                                    <div class="form-check form-switch form-switch-md">
                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                                            checked>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade bg-light" id="pills-notification" role="tabpanel"
                            aria-labelledby="pills-notification-tab">
                            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center py-2 ps-2"
                                style="box-shadow: 0px 2px 2px lightcoral;">
                                <div class="col-10">
                                    <p class="fw-semibold mb-0 text-dark">
                                        Send Money
                                    </p>
                                    <p class="mb-0 text-dark">
                                        Push Notification
                                    </p>
                                </div>
                                <div class="col-2 text-end">
                                    <div class="form-check form-switch form-switch-md">
                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                                            checked>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center py-2 ps-2"
                                style="box-shadow: 0px 2px 2px lightcoral;">
                                <div class="col-10">
                                    <p class="fw-semibold mb-0 text-dark">
                                        Request Money
                                    </p>
                                    <p class="mb-0 text-dark">
                                        Push Notification
                                    </p>
                                </div>
                                <div class="col-2 text-end">
                                    <div class="form-check form-switch form-switch-md">
                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                                            checked>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center py-2 ps-2"
                                style="box-shadow: 0px 2px 2px lightcoral;">
                                <div class="col-10">
                                    <p class="fw-semibold mb-0 text-dark">
                                        LolliCash Money
                                    </p>
                                    <p class="mb-0 text-dark">
                                        Push Notification
                                    </p>
                                </div>
                                <div class="col-2 text-end">
                                    <div class="form-check form-switch form-switch-md">
                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                                            checked>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Profile Section End -->

    <!-- Footer Start -->
    <app-footer></app-footer>
    <!-- Footer End -->
</section>