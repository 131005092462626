<section style="background-color: #15202b;">

    <!-- Nav Bar Start -->
    <app-nav-bar></app-nav-bar>
    <!-- Nav Bar End -->

    <!-- Privacy Policy Section Start -->
    <section class="section" style="padding-top: 105px; height: 100vh;">
        <div class="container">
            <div class="row">
                <h1 class="text-light text-center">Privacy Policy</h1>
            </div>
            <div class="row mt-4">
                <h2 class="text-light text-center">Overview</h2>
                <p class="text-light">This privacy statement explains how and why LolliCash collects, stores, uses, and
                    shares personal
                    information when you visit our websites, mobile app, or use our services. Reading it will help you
                    understand your privacy rights and choices. By using the Site or Service, you agree to the
                    collection and use of information by this privacy statement and the applicable user agreement
                    (LolliCash LLC). “Personal information” in this statement means information about you, including
                    your identity, finances, and online behavior. “LolliCash” in this statement means LolliCash, LLC.
                    This privacy statement applies to LolliCash, and its services offered by LolliCash.</p>
            </div>
        </div>
    </section>
    <!-- Privacy Policy Section End -->

    <!-- Footer Start -->
    <app-footer></app-footer>
    <!-- Footer End -->
</section>