import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavBarComponent } from '../nav/nav-bar/nav-bar.component';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FooterComponent } from '../nav/footer/footer.component';
import { format, subDays } from 'date-fns';
import { first } from 'rxjs';
import { ApiService } from '../../service/api.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';

export interface CurrentTransactionData {
  image: string;
  moneyDetail: string;
  money: number;
}

@Component({
  selector: 'app-dashborad',
  standalone: true,
  imports: [NavBarComponent, MatTableModule, MatPaginatorModule, FooterComponent, CommonModule, RouterModule],
  templateUrl: './dashborad.component.html',
  styleUrl: './dashborad.component.css'
})

export class DashboradComponent implements OnInit {

  constructor(private service: ApiService, private cdr: ChangeDetectorRef) { }

  userDetail: any;
  currentDate: any;
  days_ago: any;
  timezone: any;

  @ViewChild('myModal') myModal: ElementRef | undefined;

  imageUrl: any;
  ngOnInit(): void {
    if (typeof localStorage !== 'undefined') {
      this.userDetail = localStorage.getItem('user');
      if (this.userDetail) {
        this.userDetail = JSON.parse(this.userDetail);
      }
    } else {
      console.log('localStorage is not available.');
    }
    console.log(this.userDetail);

    this.currentDate = new Date();
    this.currentDate.setDate(this.currentDate.getDate() - 229);
    this.days_ago = this.currentDate.toISOString().slice(0, 19).replace('T', ' ');

    const currentTime = this.currentDate.getHours();
    if (currentTime < 12) {
      this.timezone = 'Good morning';
    } else if (currentTime >= 12 && currentTime < 17) {
      this.timezone = 'Good afternoon';
    } else if (currentTime >= 17 && currentTime < 21) {
      this.timezone = 'Good evening';
    } else {
      this.timezone = 'Good night';
    }

    this.getDashboard();
    this.getCurrency();

    this.imageUrl = environment.image_url;

    if(this.userDetail.session) {
      this.ngAfterViewInit();
    }
  }

  ngAfterViewInit() {
    this.eventTicketList();
    this.myModal?.nativeElement.classList.add('show');
    if (this.myModal?.nativeElement) {
      this.myModal.nativeElement.style.display = 'block';
    }
    this.cdr.detectChanges();
  }

  closeModel() {
    if (this.myModal?.nativeElement) {
      this.myModal.nativeElement.classList.remove('show');
      this.myModal.nativeElement.style.display = 'none';
      this.cdr.detectChanges();
    }
  }
  
  eventData: any;
  eventTicketList() {
    this.service.event_list().pipe(first()).subscribe((response: any) => {
      console.log("eventData --", response);
      this.eventData = response.data[0];
    });
  }

  dashboardData: any;
  walletId: any;
  getDashboard() {
    const formData = new FormData();
    if (this.userDetail) {
      formData.append('user_id', this.userDetail.user_id);
      formData.append('days_ago', this.days_ago);
      formData.append('session', this.userDetail.session);
      this.service.dashboard(formData).pipe(first()).subscribe((response: any) => {
        if (response) {
          console.log(response);
          this.dashboardData = response;
          this.walletId = this.dashboardData[0]?.wallet_id;
          if (this.walletId) {
            this.transactionDetail();
          }
          console.log(this.walletId);
          
        }
      });
    }
  }

  transactionDetails: any = [];
  transactionDetail() {
    const formData = new FormData();
    if (this.userDetail) {
      formData.append('wallet_id', this.walletId);
      formData.append('session', this.userDetail.session);
      this.service.transactionDetail(formData).pipe(first()).subscribe((response: any) => {
        if (response) {
          console.log("Transaction Response -- ", response);
          console.log(response.data);
          this.transactionDetails.push(response.data);
          console.log(this.transactionDetails);
          
        }
      });
    }
  }

  userCurrency: any;
  getCurrency() {
    const formData = new FormData();
    if (this.userDetail) {
      formData.append('iso', this.userDetail.country_iso);
      this.service.getCurrency(formData).pipe(first()).subscribe((response: any) => {
        if (response) {
          console.log(response);
          this.userCurrency = response;
        }
      });
    }
  }


  displayedColumns: string[] = ['image', 'moneyDetail', 'money'];
  dataSource = new MatTableDataSource<CurrentTransactionData>([
    { image: 'https://opensource.com/sites/default/files/delisa_alexander_headshot.jpg', moneyDetail: '30', money: 20 },
    { image: 'https://opensource.com/sites/default/files/delisa_alexander_headshot.jpg', moneyDetail: '25', money: 10 },
    { image: 'https://opensource.com/sites/default/files/delisa_alexander_headshot.jpg', moneyDetail: '40', money: 210 },
  ]);
}
