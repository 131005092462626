import { Component, OnInit } from '@angular/core';
import { FooterComponent } from '../nav/footer/footer.component';
import { NavBarComponent } from '../nav/nav-bar/nav-bar.component';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { DropdownModule } from 'primeng/dropdown';
import { ApiService } from '../../service/api.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-send-money',
  standalone: true,
  imports: [NavBarComponent, FooterComponent, MatSelectModule, DropdownModule, CommonModule],
  templateUrl: './send-money.component.html',
  styleUrl: './send-money.component.css'
})
export class SendMoneyComponent implements OnInit {

  constructor(private service: ApiService) { }

  countryCodes: any = [];

  ngOnInit(): void {
    this.service.getCountriesCode().pipe(first()).subscribe((response: any) => {
      this.countryCodes = response;
    });
  }

  sendShow = true;
  sendMoneyShow = false;
  transaction = false;
  mobileInputShow = true;
  radioChange(event: any) {
    if(event.target.defaultValue == 'mobile') {
      this.mobileInputShow = true;
    } else {
      this.mobileInputShow = false;
    }
  }

  goToNext() {
    this.sendMoneyShow = true;
    this.sendShow = false;
  }

  cancle() {
    this.sendShow = true;
    this.sendMoneyShow = false;
    this.transaction = false;
  }

  confirm() {
    this.transaction = true;
    this.sendMoneyShow = false;
    this.sendShow = false;
  }
}
