
@if (isLoading) {
<div class="loader-body">
    <div class="loader"></div>
</div>
} @else {
    <video src="../../../../assets/LolliCash-Video.mp4" autoplay loop playsinline class="image-bg rounded-2"></video>
<section class="section" style="background-color: #15202b; min-height: 100vh;">
    <!-- <div class="alert alert-danger d-flex align-items-center justify-content-center" role="alert">
        <div>
            Do not Refresh this Page!!!
        </div>
    </div> -->
    <div class="modal fade" id="buyTicket" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="buyTicketLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="background-color: #15202b;">
                <div class="modal-header d-flex justify-content-center">
                    <h2 class="modal-title text-light mb-0" id="buyTicketLabel">How many tickets do you want to buy
                        ?</h2>
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                </div>
                <div class="modal-body">
                    <div class="d-flex flex-wrap justify-content-center align-items-center">
                        <button class="btn btn-light me-3 p-3" (click)="decrement()" style="line-height: 5px;"><i
                                class="fi fi-br-minus-small"></i></button>
                        <h2 class="text-light mb-0" style="vertical-align: middle;">{{ count }}</h2>
                        <button class="btn btn-light ms-3 p-3" (click)="increment()" style="line-height: 5px;"><i
                                class="fi fi-br-plus-small"></i></button>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    @if(count > 1) {
                    <button type="button" class="btn btn-danger" data-bs-target="#buyTicket2" data-bs-toggle="modal"
                        data-bs-dismiss="modal" (click)="next()">Next</button>
                    } @else {
                    <button type="button" class="btn btn-danger" data-bs-target="#buyTicket2" data-bs-toggle="modal"
                        data-bs-dismiss="modal" (click)="next()">Next</button>
                    }
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="modal fade" id="buyTicketTemp2" data-bs-backdrop="static" aria-hidden="true"
        aria-labelledby="buyTicketTempLabel2" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="background-color: #15202b;">
                <div class="modal-body text-light d-flex justify-content-center">
                    <h2>Do you want to distribute tickets?</h2>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" data-bs-toggle="modal" data-bs-dismiss="modal"
                        (click)="sendTicketToCurrentEmail()">No</button>
                    <button type="button" class="btn btn-danger" data-bs-target="#buyTicket2" data-bs-toggle="modal"
                        data-bs-dismiss="modal" (click)="showTicketDataFillModel()">Yes</button>
                </div>
            </div>
        </div>
    </div> -->

    <div class="modal fade" id="buyTicket2" data-bs-backdrop="static" aria-hidden="true"
        aria-labelledby="buyTicketLabel2" tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content" style="background-color: #15202b;">
                <form [formGroup]="ticketSendForm" (ngSubmit)="sendTicketEmail()">
                    <div class="modal-header d-flex justify-content-center">
                        <h2 class="modal-title text-light mb-0" id="buyTicketLabel2">Ticket Recipient Email Details</h2>
                        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                    </div>
                    <div class="modal-body text-light d-flex flex-wrap justify-content-center">
                        @if(emailData.length > 0) {
                        <table class="col-12 col-md-8 mb-4">
                            <thead>
                                <tr>
                                    <th>Ticket</th>
                                    <th>Email</th>
                                    <!-- @if(count > 1) { -->
                                    <th>Action</th>
                                    <!-- } -->
                                </tr>
                            </thead>
                            <tbody>
                                @for (item of emailData; track $index) {
                                <tr>
                                    <td>{{item.ticket_count}}</td>
                                    <td>{{item.email}}</td>
                                    <!-- @if(count > 1) { -->
                                    <td>
                                        <i class="fi fi-br-trash text-danger" style="cursor: pointer;"
                                            (click)="deletEmailSendTicket($index)"></i>
                                    </td>
                                    <!-- } -->
                                </tr>
                                }
                            </tbody>
                        </table>
                        }
                        @if(showErrors && count > 1){
                        @if (ticketSendForm.controls['ticket_count'].errors?.['required']) {
                        <p class="text-danger">
                            Ticket number is required.
                        </p>
                        }
                        @if (ticketSendForm.controls['ticket_count'].errors?.['pattern']) {
                        <p class="text-danger">
                            Ticket number must contain only digits.
                        </p>
                        }
                        @if (ticketSendForm.controls['email'].errors?.['required']) {
                        <p class="text-danger">
                            Email is required.
                        </p>
                        }
                        @if (ticketSendForm.controls['email'].errors?.['email']) {
                        <p class="text-danger">
                            Please enter a valid email address.
                        </p>
                        }
                        }
                        @if (maxTicket) {
                        <p class="text-danger">
                            You Have Only {{count}} Tickets.
                        </p>
                        }
                        @if (hideInput === false) {
                        <div
                            class="col-12 col-md-8 form-row d-flex flex-wrap justify-content-around align-items-center">
                            <div class="form-group row col-12 col-md-4 text-center">
                                <label class="col-form-label">No. of tickets*</label>
                                <div class="input-group col-12 justify-content-center">
                                    <!-- <input type="text" class="form-control" placeholder="Enter Ticket Count"
                                        formControlName="ticket_count"> -->
                                    <p-dropdown appendTo="body" [options]="countArray" optionValue="value"
                                        optionLabel="value" placeholder="Tickets" formControlName="ticket_count"
                                        styleClass="col-12" [style]="{'width': '100%'}">
                                    </p-dropdown>
                                </div>
                            </div>
                            <div class="form-group row col-12 col-md-8 text-center">
                                <label class="col-form-label">Recipient email address*</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter Email"
                                        formControlName="email">
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" data-bs-target="#buyTicket" data-bs-toggle="modal"
                            data-bs-dismiss="modal">Back</button>
                        @if(showPayButton) {
                        <!-- data-bs-target="#cardDetail data-bs-toggle="modal" data-bs-dismiss="modal" -->
                        <button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-dismiss="modal"
                            (click)="submitData()">Submit</button>
                        } @else {
                        <button type="submit" class="btn btn-danger">Next</button>
                        }
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- For User Has Already Card -->
    <div class="modal fade" id="cardDetail" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="cardDetailLabel" aria-hidden="true" #myModal>
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="background-color: #FFEBEC;">
                <form [formGroup]="cardDetailForm" (ngSubmit)="addCardDetail()">
                    <div class="modal-header">
                        <h2 class="modal-title" id="cardDetailLabel">Add Debit/ATM Card Details</h2>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            (click)="closeModel()"></button>
                    </div>
                    <div class="modal-body">
                        <div class="d-flex flex-wrap justify-content-center align-items-center">
                            @for (item of cardList; track $index) {
                            <input type="radio" name="radio" value="{{item.card_name}}">
                            <div class="debit-card col-10" (click)="chooseCard(item)" style="cursor: pointer;"
                                [class.selected]="item === selectedCard">
                                <div class="top">
                                    <h2 class="mb-0">{{item.card_name}}</h2>
                                    <img src="https://cdn-icons-png.flaticon.com/512/1436/1436392.png" alt="image" />
                                </div>
                                <div class="infos">
                                    <section class="card-number">
                                        <p class="mb-0">Card Number</p>
                                        <h1 class="mb-0">{{item.card_number}}</h1>
                                    </section>
                                    <div class="bottom">
                                        <aside class="infos--bottom">
                                            <section>
                                                <p class="mb-0">Expiry date</p>
                                                <h3 class="mb-0">{{item.card_expiry_month}}/{{item.card_expiry_year}}
                                                </h3>
                                            </section>
                                            <section>
                                                <p class="mb-0">CVV</p>
                                                <h3 class="mb-0">XXX</h3>
                                            </section>
                                        </aside>
                                        <aside>
                                            <section>
                                                <img src="https://seeklogo.com/images/V/VISA-logo-DD37676279-seeklogo.com.png"
                                                    class="brand" alt="image" />
                                            </section>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary my-3 col-12" data-bs-target="#buyTicket2"
                            data-bs-toggle="modal" data-bs-dismiss="modal" (click)="closeModel()">Cancle</button>
                        @if (this.cardDetailForm.valid === false) {
                        <button type="submit" class="btn btn-danger col-12">Submit</button>
                        } @else {
                        <button type="submit" class="btn btn-danger col-12" data-bs-toggle="modal"
                            data-bs-dismiss="modal" (click)="closeModel()" data-bs-target="#amount">Submit</button>
                        }
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- For User Don't have any Card -->
    <div class="modal fade" id="cardDetail" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="cardDetailLabel" aria-hidden="true" #myModal2>
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="background-color: #FFEBEC;">
                <form [formGroup]="cardDetailForm" (ngSubmit)="addCardDetail()">
                    <div class="modal-header">
                        <h2 class="modal-title" id="cardDetailLabel">Add Debit/ATM Card Details</h2>
                        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            (click)="closeModel2()"></button> -->
                    </div>
                    <div class="modal-body">
                        <div class="form-row d-flex flex-wrap justify-content-between align-items-center">
                            <div class="form-group row col-12 my-2">
                                <div class="input-group">
                                    <div class="col-12">
                                        @if(showErrors){
                                        @if (cardDetailForm.controls['cardNumber'].invalid)
                                        {
                                        <div>
                                            @if (cardDetailForm.controls['cardNumber'].errors?.['required']) {
                                            <p class="text-danger">
                                                Card Number is required Property.
                                            </p>
                                            }
                                            @if (cardDetailForm.controls['cardNumber'].errors?.['minlength'] ||
                                            cardDetailForm.controls['cardNumber'].errors?.['maxlength']) {
                                            <p class="text-danger">
                                                Card Number must be 16 digits long.
                                            </p>
                                            }
                                            @if (cardDetailForm.controls['cardNumber'].errors?.['pattern']) {
                                            <p class="text-danger">
                                                Invalid Card Number.
                                            </p>
                                            }
                                        </div>
                                        }
                                        }
                                        @if (cardError) {
                                        <p class="text-danger">
                                            We do not accept credit card - the ONLY acceptable payment method on
                                            LolliCash is Debit Card.
                                        </p>
                                        } @if (cardLevelError) {
                                        <p class="text-danger">
                                            We do not accept {{cardErrorRes.level}} Card.
                                        </p>
                                        }
                                    </div>
                                    <input type="text" class="form-control" formControlName="cardNumber"
                                        placeholder="Enter Card Number*" maxlength="16" minlength="16"
                                        (change)="cardNumberChange($event)">
                                </div>
                            </div>
                            <div class="form-group row col-12 col-md-4 my-2">
                                <div class="input-group">
                                    <div class="col-12">
                                        @if(showErrors) {
                                        @if (cardDetailForm.controls['month'].invalid)
                                        {
                                        <div>
                                            @if (cardDetailForm.controls['month'].errors?.['required']) {
                                            <p class="text-danger">
                                                Month is required.
                                            </p>
                                            }
                                            @if (cardDetailForm.controls['month'].errors?.['min'] ||
                                            cardDetailForm.controls['month'].errors?.['max']) {
                                            <p class="text-danger">
                                                Month must be between 1 and 12.
                                            </p>
                                            }
                                            @if (cardDetailForm.controls['month'].errors?.['pattern']) {
                                            <p class="text-danger">
                                                Invalid Month.
                                            </p>
                                            }
                                        </div>
                                        }
                                        }
                                    </div>
                                    <input type="text" class="form-control" formControlName="month" placeholder="MM*"
                                        maxlength="2" minlength="2">
                                </div>
                            </div>
                            <div class="form-group row col-12 col-md-4 my-2">
                                <div class="input-group">
                                    <div class="col-12">
                                        @if (showErrors) {
                                        @if (cardDetailForm.controls['year'].invalid)
                                        {
                                        <div>
                                            @if (cardDetailForm.controls['year'].errors?.['required']) {
                                            <p class="text-danger">
                                                Year is required.
                                            </p>
                                            }
                                            @if (cardDetailForm.controls['year'].errors?.['min']) {
                                            <p class="text-danger">
                                                Year must be the current year or later.
                                            </p>
                                            }
                                            @if (cardDetailForm.controls['year'].errors?.['pattern']) {
                                            <p class="text-danger">
                                                Invalid Year.
                                            </p>
                                            }
                                        </div>
                                        }
                                        }
                                    </div>
                                    <input type="text" class="form-control" formControlName="year" placeholder="YYYY*"
                                        minlength="4" maxlength="4">
                                </div>
                            </div>
                            <div class="form-group row col-12 col-md-4 my-2">
                                <div class="input-group">
                                    <div class="col-12">
                                        @if (showErrors) {
                                        @if (cardDetailForm.controls['cvv'].invalid)
                                        {
                                        <div>
                                            @if (cardDetailForm.controls['cvv'].errors?.['required']) {
                                            <p class="text-danger">
                                                CVV is required.
                                            </p>
                                            }
                                            @if (cardDetailForm.controls['cvv'].errors?.['minlength'] ||
                                            cardDetailForm.controls['cvv'].errors?.['maxlength']) {
                                            <p class="text-danger">
                                                CVV must be 3 digits long.
                                            </p>
                                            }
                                            @if (cardDetailForm.controls['cvv'].errors?.['pattern']) {
                                            <p class="text-danger">
                                                Invalid CVV.
                                            </p>
                                            }
                                        </div>
                                        }
                                        }
                                    </div>
                                    <input type="text" class="form-control" formControlName="cvv" placeholder="CVV*"
                                        minlength="3" maxlength="3">
                                </div>
                            </div>
                            <div class="form-group row col-12 my-2">
                                <div class="input-group">
                                    <div class="col-12">
                                        @if (showErrors) {
                                        @if (cardDetailForm.controls['cardName'].invalid)
                                        {
                                        <div>
                                            @if (cardDetailForm.controls['cardName'].errors?.['required']) {
                                            <p class="text-danger">
                                                Card Name is required.
                                            </p>
                                            }
                                        </div>
                                        }
                                        }
                                    </div>
                                    <input type="text" class="form-control" formControlName="cardName"
                                        placeholder="Enter Name on Card*">
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center pt-3 pb-2">
                                <div class="col-2">
                                    <img src="../../../assets/privacy.png"
                                        style="width: 100%; height: 100%; object-fit: cove;" alt="Privacy Icon">
                                </div>
                                <div class="col-10 px-2">
                                    <p>
                                        Your information is safe and secured - we use industry standard and highly
                                        secure
                                        encryption process to protect your personal information.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-secondary my-3 col-12" data-bs-target="#buyTicket2"
                            data-bs-toggle="modal" data-bs-dismiss="modal" (click)="closeModel2()">Cancle</button> -->
                        @if (this.cardDetailForm.valid === false) {
                        <button type="submit" class="btn btn-danger col-12">Submit</button>
                        } @else {
                        <!-- <button type="submit" class="btn btn-danger col-12" data-bs-target="#amount"
                            data-bs-toggle="modal" data-bs-dismiss="modal" (click)="closeModel2()">Submit</button> -->
                            <button type="submit" class="btn btn-danger col-12" data-bs-target="#amount"
                            data-bs-toggle="modal" data-bs-dismiss="modal" (click)="transactionPreview()">Submit</button>
                        }
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="modal fade" id="amount" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="amountLabel" aria-hidden="true" #amountModel>
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content" style="background-color: #15202b;">
                <form [formGroup]="amountDescForm" (ngSubmit)="saveAmountAndDesc()">
                    <div class="modal-header justify-content-center">
                        <h2 class="modal-title text-light mb-0" id="amountLabel">{{ticketName}}</h2>
                        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            (click)="closeAmountModel()"></button> -->
                    </div>
                    <div class="modal-body">
                        <div class="d-flex flex-wrap justify-content-center align-items-center">
                            <div class="col-12 col-md-8 text-center">
                                <h3 class="text-light">Ticket Total Amount</h3>
                                <h1 class="text-light">{{ticketPrice * count}}</h1>
                            </div>
                            <div class="col-12 col-md-8">
                                <div class="form-row d-flex flex-wrap justify-content-between align-items-center">
                                    <div class="form-group row col-12 my-2">
                                        <div class="input-group">
                                            <input type="text" formControlName="description" class="form-control"
                                                placeholder="Description">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" data-bs-target="#cardDetail" data-bs-toggle="modal"
                            data-bs-dismiss="modal" (click)="closeAmountModel()">Close</button>
                        <button type="button" class="btn btn-danger" (click)="transactionPreview()"
                            data-bs-target="#transactionPreview" data-bs-toggle="modal"
                            data-bs-dismiss="modal">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="modal fade" id="transactionPreview" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="transactionPreviewLabel" aria-hidden="true" #transactionModel>
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="background-color: #15202b;">
                <div class="modal-header">
                    <h2 class="modal-title text-light mb-0" id="transactionPreviewLabel">{{ticketName}}</h2>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        (click)="closeTransactionModel()"></button>
                </div>
                <div class="modal-body">
                    <div class="d-flex flex-wrap justify-content-center align-items-center">
                        <div class="col-12 text-center">
                            <h2 class="text-uppercase text-light">Transaction review</h2>
                        </div>
                        <div class="col-12">
                            <p class="text-light my-1">Transfer Amount: {{transactionPreviewData?.user_currency}}
                                {{transactionPreviewData?.amount_to_receive}}</p>
                            <!-- <p class="text-light my-1">Back Taxes/Fees: {{transactionPreviewData?.user_currency}}
                                {{transactionPreviewData?.trasaction_fee}}</p> -->
                            <p class="text-light my-1">Total Amount Paid: {{transactionPreviewData?.user_currency}}
                                {{transactionPreviewData?.total_amount_paid}}</p>
                            <p class="text-light my-1">Date & Time: {{transactionPreviewData?.transaction_date}}
                                {{transactionPreviewData?.transaction_time}}</p>
                            <p class="text-light my-1">Source of Transaction: {{cardNumberLastFourDigits}}</p>
                            <!-- <p class="mb-4 text-light">Description: {{amountDescForm.get('description')?.value}}</p> -->
                            <p class="text-light my-1">Transaction Type: Card</p>
                            <!-- <p class="text-light my-1">To: {{transactionPreviewData?.to}}</p>
                            <p class="text-light my-1">From: {{transactionPreviewData?.from}}</p> -->
                            <p class="text-light my-1">Event Name: {{eventName}} {{ticketName}}</p>
                            <p class="text-light my-1">Number of ticket purchase:
                                {{transactionPreviewData?.total_tickets}}</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-target="#amount" data-bs-toggle="modal"
                        data-bs-dismiss="modal" (click)="closeTransactionModel()">Close</button>
                    <button type="button" class="btn btn-danger" (click)="finalSubmit()">Submit</button>
                </div>
            </div>
        </div>
    </div>


    <div class="container">
        <h1 class="text-center text-light">{{ticketList[0]?.event_name}}</h1>
        <div class="d-flex flex-wrap justify-content-center align-items-center">
            @for (item of ticketList; track $index) {
            <div class="card col-12 col-md-7"
                style="box-shadow: 0px 3px 10px rgba(255, 255, 255, 0.2); background-color: #1e1e1e;"
                data-bs-toggle="modal" data-bs-target="#buyTicket">
                <div class="d-flex flex-wrap justify-content-center align-items-center col-12">
                    <div class="col-12 col-md-4 ps-md-4  pt">
                        <img src="{{imageUrl + item.ticket_image_url}}" class="card-img-top" alt="Ticket Image"
                            height="80%" width="80%" style="object-fit: cover;">
                    </div>
                    <div class="card-body col-12 col-md-8" style="padding: 20px 50px;">
                        <p>{{item.start_at}}</p>
                        <h1 class="text-light">{{item.ticket_name}}</h1>
                        <h2 class="text-light">{{item.price}}</h2>
                        <button type="button" class="btn btn-outline-danger" (click)="ticketClick(item)">Buy Ticket Now</button>
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
</section>
}