import { Component } from '@angular/core';
import { NavBarComponent } from '../nav/nav-bar/nav-bar.component';
import { FooterComponent } from '../nav/footer/footer.component';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bank-information',
  standalone: true,
  imports: [NavBarComponent, FooterComponent, CommonModule],
  templateUrl: './bank-information.component.html',
  styleUrl: './bank-information.component.css'
})
export class BankInformationComponent {

  constructor(private router: Router) {}

  addMoney() {
    this.router.navigate(['/add_money']);
  }

  depositMoney() {
    this.router.navigate(['/deposit_money']);
  }

}
