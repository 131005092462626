import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Subscription, catchError, first, throwError } from "rxjs";
import { ApiService } from "../../../service/api.service";
import { environment } from "../../../../environments/environment";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { SharedDataService } from "../../../service/shared/shared-data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DropdownModule } from "primeng/dropdown";

@Component({
  selector: "app-event-ticket-list",
  standalone: true,
  imports: [ReactiveFormsModule, DropdownModule],
  templateUrl: "./event-ticket-list.component.html",
  styleUrl: "./event-ticket-list.component.css",
})
export class EventTicketListComponent implements OnInit {
  @ViewChild("myModal") myModal: ElementRef | undefined;
  @ViewChild("myModal2") myModal2: ElementRef | undefined;
  @ViewChild("amountModel") amountModel: ElementRef | undefined;
  @ViewChild("transactionModel") transactionModel: ElementRef | undefined;

  ticketSendForm: FormGroup;
  cardDetailForm: FormGroup;
  amountDescForm: FormGroup;
  ticketUserData: any;
  public dataSubscription: Subscription | undefined;
  isLoading = false;

  constructor(
    private service: ApiService,
    private formBuilder: FormBuilder,
    private sharedDataService: SharedDataService,
    private cdr: ChangeDetectorRef,
    public router: Router,
    private route: ActivatedRoute
  ) {
    this.ticketSendForm = this.formBuilder.group({
      ticket_count: ["", [Validators.required, Validators.pattern("[0-9]+")]],
      email: ["", [Validators.required, Validators.email]],
    });
    // this.cardDetailForm = this.formBuilder.group({
    //   cardNumber: ['', Validators.required],
    //   month: ['', Validators.required],
    //   year: ['', Validators.required],
    //   cvv: ['', Validators.required],
    //   cardName: ['', Validators.required]
    // });
    this.cardDetailForm = this.formBuilder.group({
      cardNumber: [
        "",
        [
          Validators.required,
          Validators.minLength(16),
          Validators.maxLength(16),
          Validators.pattern(/^\d+$/),
        ],
      ],
      month: [
        "",
        [
          Validators.required,
          Validators.min(1),
          Validators.max(12),
          Validators.pattern(/^\d+$/),
        ],
      ],
      year: [
        "",
        [
          Validators.required,
          Validators.min(new Date().getFullYear()),
          Validators.pattern(/^\d+$/),
        ],
      ],
      cvv: [
        "",
        [
          Validators.minLength(3),
          Validators.maxLength(3),
          Validators.pattern(/^\d+$/),
        ],
      ],
      cardName: ["", Validators.required],
    });
    this.amountDescForm = this.formBuilder.group({
      amount: [""],
      description: [""],
    });
  }

  // ngOnDestroy() {
  //   this.dataSubscription.unsubscribe();
  // }

  imageUrl: any;
  userDetail: any;
  buyTicketId: any;
  ngOnInit(): void {
    this.eventId = this.route.snapshot.paramMap.get("event_id");
    console.log("Event ID:", this.eventId);
    if (typeof localStorage !== "undefined") {
      this.userDetail = localStorage.getItem("user");

      console.log("userDetail", this.userDetail);

      if (this.userDetail) {
        this.userDetail = JSON.parse(this.userDetail);
      }
    } else {
      console.log("localStorage is not available.");
    }
    if (this.userDetail?.session) {
    const formData = new FormData();
    formData.append('session', this.userDetail.session);
    formData.append("platform", environment.platform);

    this.service
      .stripe_event_sender(formData)
      .pipe(first())
      .subscribe((response: any) => {
        console.log("data", response);
        if (response) {
          this.ticketUserData = response;
          this.buyTicketId = response?.ticket_buy_id;
        }
      });
    } else {
    this.dataSubscription = this.sharedDataService.data$.subscribe(data => {
      this.ticketUserData = data;
      this.buyTicketId = data?.ticket_buy_id;
    });
    }
    this.imageUrl = environment.image_url;
    this.eventTicketList();
    console.log("data 11 ", this.ticketUserData);
  }

  countArray: Array<any> = [];
  next() {
    console.log(this.count);
    const formData = new FormData();
    formData.append("ticket_id", this.ticketId);
    formData.append("event_id", this.eventId);
    formData.append("tickets", this.count.toString());

    this.service
      .stripe_ticket_availability(formData)
      .pipe(first())
      .subscribe((response: any) => {
        console.log(response);
        if (response) {
          if (response.error_msg == "Ticket has not available") {
            return this.service.notify.showSuccess(response.error_msg);
          }
          this.service.notify.showSuccess(response.error_msg);
        }
      });

    let newCountArray = [];
    for (let i = 0; i <= this.count; i++) {
      const newObj = { value: i + 1 };
      // this.countArray.push(newObj);
      newCountArray.push(newObj);
    }
    console.log("newCountArray --", newCountArray);

    this.countArray = newCountArray;
    this.countArray.splice(-1);
    console.log("countArray --", this.countArray);

    this.ticketCountCheck.push(this.count);
    // this.changeTicketValue(parseInt(this.ticketSendForm.get('ticket_count')?.value));
    console.log("this is a ticketCountCheck", this.ticketCountCheck);
    let sum = this.countArray.length;
    // let sum = this.ticketCountCheck.reduce((total, currentValue) => total + currentValue, 0);
    console.log("sum --", sum);

    if (this.count <= 1) {
      this.showPayButton = true;
      this.hideInput = true;
      // email: this.ticketUserData.data.user_email || ''
      if (this.ticketUserData != null) {
        this.emailData = [{ ticket_count: 1 }];
      }

      this.cdr.detectChanges();
      // this.submitData();
    } else {
      // email: this.ticketUserData.data.user_email || ''
      if (this.ticketUserData != null) {
        this.emailData = [{ ticket_count: this.count }];
      }
      this.cdr.detectChanges();
      if (sum > this.count) {
        this.ticketCountCheck.pop();
        this.ticketSendForm.reset();
        this.maxTicket = true;
        return;
      }
      this.maxTicket = false;
      console.log("Summm", sum);
      console.log("Counttt", this.count);

      if (sum == this.count) {
        this.showPayButton = true;
        this.hideInput = true;
        this.showErrors = false;
      } else {
        // this.emailData = [{
        //   ticket_count: 1,
        //   email: this.ticketUserData.data.user_email
        // }];
        this.cdr.detectChanges();
        this.showPayButton = false;
        this.hideInput = false;
      }
    }
    this.hideInput = true;
    console.log("---", this.hideInput);
    sum = 0;

    this.deletEmailSendTicket(0);
  }

  // showTicketDataFillModel() {
  //   this.emailData = [{
  //     ticket_count: 1,
  //     email: this.ticketUserData.data.user_email
  //   }];
  //   this.cdr.detectChanges();
  // }

  changeTicketValue(event: any) {
    console.log("Event value -- ", event);
    this.countArray.splice(-event);
    this.cdr.detectChanges();
    console.log("Count Array --", this.countArray);
  }

  // sendTicketToCurrentEmail() {
  //   this.hideInput = true;
  //   this.cdr.detectChanges();
  //   this.emailData = [{
  //     ticket_count: 1,
  //     email: this.ticketUserData.data.user_email
  //   }];
  //   this.submitData();
  // }

  ticketList: Array<any> = [];
  // eventTicketList() {
  //   this.service
  //     .event_list()
  //     .pipe(first())
  //     .subscribe((response: any) => {
  //       this.ticketList = response.data;
  //       console.log(this.ticketList);
  //     });
  // }

  eventTicketList() {
    this.service
      .event_list()
      .pipe(first())
      .subscribe((response: any) => {
        this.ticketList = response.data;
        console.log("Original Ticket List:", this.ticketList);

        // Filter the ticket list based on the eventId from the URL
        this.ticketList = this.ticketList.filter(
          (ticket) => ticket.event_id === this.eventId
        );

        console.log("Filtered Ticket List:", this.ticketList);
      });
  }

  ticketName: any = "";
  ticketId: any;
  eventId: any;
  eventName: any;
  ticketPrice: any;
  ticketClick(item: any) {
    console.log(item);
    this.ticketName = item.ticket_name;
    this.ticketId = item.ticket_id;
    this.eventId = item.event_id;
    let price = item.price;
    this.eventName = item.event_name;
    let numericPart = price.split(" ")[1];
    this.ticketPrice = parseInt(numericPart);
    console.log(this.ticketPrice);
    // this.staticUser();
  }

  // staticUser() {
  //   // let countryCode = this.countryCodes.find((country: any) => country.code == this.eventTicketForm.get('country_code')?.value).dial_code;

  //   const formData = new FormData();

  //   formData.append("first_name", "test"); //this.eventTicketForm.get('first_name')?.value);
  //   // formData.append('middle_name', this.eventTicketForm.get('middle_name')?.value);
  //   formData.append("last_name", "lName"); //this.eventTicketForm.get('last_name')?.value);
  //   formData.append("country_code", "+91"); //countryCode.replace('+', ''));
  //   formData.append(
  //     "user_mobile",
  //     String(Math.floor(1000000000 + Math.random() * 9000000000))
  //   ); //this.eventTicketForm.get('user_mobile')?.value);
  //   formData.append("user_email", "test@gmail.com"); //this.eventTicketForm.get('user_email')?.value);
  //   formData.append("address", "113 ,test testtest"); //this.eventTicketForm.get('address')?.value);
  //   formData.append("user_city", "test"); //this.eventTicketForm.get('user_city')?.value);
  //   formData.append("user_state", "test"); //this.eventTicketForm.get('user_state')?.value);
  //   formData.append("postal_code", "test"); //this.eventTicketForm.get('postal_code')?.value);
  //   formData.append("state_sort_code", "test"); //this.eventTicketForm.get('state_sort_code')?.value);
  //   formData.append("country_iso", "test"); //this.eventTicketForm.get('country_iso')?.value);
  //   formData.append("country", "test"); //this.eventTicketForm.get('country')?.value);
  //   formData.append("platform", environment.platform);

  //   this.service
  //     .stripe_event_sender(formData)
  //     .pipe(first())
  //     .subscribe((response: any) => {
  //       console.log(response);
  //       if (response) {
  //         this.sharedDataService.setData(response);
  //         this.router.navigate(["ticket_list"]);
  //       }
  //     });
  // }

  count: number = 1;

  increment() {
    this.count++;
  }

  decrement() {
    if (this.count > 1) {
      this.count--;
    }
  }

  ticketCountCheck: Array<any> = [];
  emailData: Array<any> = [];
  showPayButton = false;
  hideInput: boolean | undefined;
  showErrors = false;
  maxTicket = false;
  sendTicketEmail() {
    this.showErrors = true;
    console.log("this.ticketSendForm.value --", this.ticketSendForm.value);

    if (this.ticketSendForm.valid) {
      this.showErrors = false;
      this.ticketCountCheck.push(
        parseInt(this.ticketSendForm.get("ticket_count")?.value)
      );
      this.changeTicketValue(
        parseInt(this.ticketSendForm.get("ticket_count")?.value)
      );
      console.log("this is a ticketCountCheck", this.ticketCountCheck);
      let sum = this.ticketCountCheck.reduce(
        (total, currentValue) => total + currentValue,
        0
      );
      console.log("this is a sum in sendTicketEmail", sum);
      if (sum > this.count) {
        this.ticketCountCheck.pop();
        this.ticketSendForm.reset();
        this.maxTicket = true;
        return;
      }
      this.maxTicket = false;

      if (sum == this.count) {
        this.showPayButton = true;
        this.hideInput = true;
      }
      this.emailData.push(this.ticketSendForm.value);
      console.log(this.ticketSendForm.value);
      this.ticketSendForm.reset();
    } else {
      // if (this.ticketSendForm.value.ticket === null || this.ticketSendForm.value.ticket === '') {
      //   return this.service.notify.showSuccess('Ticket is required Property');
      // }
      // if (this.ticketSendForm.value.email === null || this.ticketSendForm.value.email === '') {
      //   return this.service.notify.showSuccess('Email is required Property');
      // }
      // if (this.ticketSendForm.get('ticket')?.hasError('pattern')) {
      //   return this.service.notify.showSuccess('Ticket number must contain only digits.');
      // }
      // if (this.ticketSendForm.get('email')?.hasError('email')) {
      //   return this.service.notify.showSuccess('Please enter a valid email address.');
      // }
    }
  }

  deletEmailSendTicket(index: any) {
    this.emailData.splice(index, 1);
    this.ticketCountCheck.splice(index, 1);
    let sum = this.ticketCountCheck.reduce(
      (total, currentValue) => total + currentValue,
      0
    );
    console.log("this is a Sum", sum);
    if (sum > this.count) {
      this.ticketCountCheck.pop();
      this.ticketSendForm.reset();
      this.maxTicket = true;
      return;
    }
    this.maxTicket = false;
    if (sum == this.count) {
      this.showPayButton = true;
      this.hideInput = true;
    } else {
      this.showPayButton = false;
      this.hideInput = false;
    }
  }

  cardList: Array<any> = [];
  submitData() {
    // debugger
    this.cdr.detectChanges();
    const formData = new FormData();
    formData.append("ticket_id", this.ticketId);
    formData.append(
      "ticket_buy_id",
      this.ticketUserData.ticket_buy_id?.toString()
    );
    formData.append("event_id", this.eventId);
    formData.append("guest_user_id", this.ticketUserData.data.id);
    formData.append("email", this.ticketUserData.data.user_email);
    formData.append("ticket_count", this.count.toString());

    console.log(this.emailData);

    let emailDataString = JSON.stringify(this.emailData);
    console.log(emailDataString);

    formData.append("email_data", emailDataString);

    this.service
      .event_ticket_emails(formData)
      .pipe(
        first(),
        catchError((error) => {
          console.error("Error occurred:", error);
          return throwError(error);
        })
      )
      .subscribe((response: any) => {
        console.log(response);
      });

    console.log("data 1 ", this.userDetail?.session);
    if (this.userDetail?.session) {
      const formData = new FormData();
      formData.append("session", this.userDetail.session);
      formData.append("updte", "1");

      this.service
        .get_accounts(formData)
        .pipe(
          first(),
          catchError((error) => {
            console.error("Error occurred:", error);
            return throwError(error);
          })
        )
        .subscribe((response: any) => {
          console.log("data 2", response.card);
          this.cardList = response.card;
          console.log("data 3", this.cardList);

          if (response.card.length > 0) {
            this.myModal?.nativeElement.classList.add("show");
            if (this.myModal?.nativeElement) {
              this.myModal.nativeElement.style.display = "block";
            }
            this.cdr.detectChanges();
          } else {
            this.myModal2?.nativeElement.classList.add("show");
            if (this.myModal2?.nativeElement) {
              this.myModal2.nativeElement.style.display = "block";
            }
            this.cdr.detectChanges();
          }
        });
    } else {
      this.myModal2?.nativeElement.classList.add("show");
      if (this.myModal2?.nativeElement) {
        this.myModal2.nativeElement.style.display = "block";
      }
      this.cdr.detectChanges();
    }
  }

  closeModel() {
    if (this.myModal?.nativeElement) {
      this.myModal.nativeElement.classList.remove("show");
      this.myModal.nativeElement.style.display = "none";
      this.cdr.detectChanges();
    }
    this.amountModel?.nativeElement.classList.add("show");
    if (this.amountModel?.nativeElement) {
      this.amountModel.nativeElement.style.display = "block";
    }
    this.cdr.detectChanges();
  }

  closeModel2() {
    if (this.myModal2?.nativeElement) {
      this.myModal2.nativeElement.classList.remove("show");
      this.myModal2.nativeElement.style.display = "none";
      this.cdr.detectChanges();
    }
    this.amountModel?.nativeElement.classList.add("show");
    if (this.amountModel?.nativeElement) {
      this.amountModel.nativeElement.style.display = "block";
    }
    this.cdr.detectChanges();
  }

  closeAmountModel() {
    if (this.amountModel?.nativeElement) {
      this.amountModel.nativeElement.classList.remove("show");
      this.amountModel.nativeElement.style.display = "none";
      this.cdr.detectChanges();
    }
  }

  closeTransactionModel() {
    if (this.transactionModel?.nativeElement) {
      this.transactionModel.nativeElement.classList.remove("show");
      this.transactionModel.nativeElement.style.display = "none";
      this.cdr.detectChanges();
    }
  }

  selectedCard: any;

  chooseCard(item: any) {
    this.selectedCard = item;
    this.cardDetailForm.get("cardNumber")?.setValue(item.card_number);
    this.cardDetailForm.get("month")?.setValue(item.card_expiry_month);
    this.cardDetailForm.get("year")?.setValue(item.card_expiry_year);
    this.cardDetailForm.get("cardName")?.setValue(item.card_name);
    this.cardDetail = this.cardDetailForm.value;
    this.cardNumberLastFourDigits = this.cardDetail.cardNumber.slice(-4);
  }

  cardError = false;
  cardLevelError = false;
  cardErrorRes: any;
  cardNumberChange(event: any) {
    console.log("test event", event.target.value);
    let cardNumber = event.target.value;
    this.service
      .cardNumberCheck(cardNumber)
      .pipe(first())
      .subscribe((response: any) => {
        console.log(response);
        this.cardErrorRes = response;
        if (response.type !== "DEBIT") {
          this.cardError = true;
        } else {
          this.cardError = false;
        }
        if (
          response.type === "DEBIT" &&
          (response.level === "GIFT" || response.level === "PREPAID")
        ) {
          this.cardLevelError = true;
        } else {
          this.cardLevelError = false;
        }
      });
  }

  cardDetail: any;
  cardNumberLastFourDigits: any;
  addCardDetail() {
    this.showErrors = true;
    console.log(this.cardDetailForm.valid);

    console.log(this.cardDetailForm.value);
    if (this.cardDetailForm.valid) {
      this.showErrors = false;
      this.cardDetail = this.cardDetailForm.value;
      this.cardNumberLastFourDigits = this.cardDetail.cardNumber.slice(-4);
    } else {
      if (
        this.cardDetailForm.value.cardNumber === null ||
        this.cardDetailForm.value.cardNumber === ""
      ) {
        return this.service.notify.showSuccess(
          "Card Number is required Property"
        );
      }
      if (
        this.cardDetailForm.value.month === null ||
        this.cardDetailForm.value.month === ""
      ) {
        return this.service.notify.showSuccess(
          "Card Expiry Month is required Property"
        );
      }
      if (
        this.cardDetailForm.value.year === null ||
        this.cardDetailForm.value.year === ""
      ) {
        return this.service.notify.showSuccess(
          "Card Expiry Year is required Property"
        );
      }
      // if (this.cardDetailForm.value.cvv === null || this.cardDetailForm.value.cvv === '') {
      //   return this.service.notify.showSuccess('CVV is required Property');
      // }
      if (
        this.cardDetailForm.value.cardName === null ||
        this.cardDetailForm.value.cardName === ""
      ) {
        return this.service.notify.showSuccess(
          "Card Holder Name is required Property"
        );
      }
    }
  }

  transactionPreviewData: any;
  transactionData: any;
  transactionPreview() {
    // if (this.myModal2?.nativeElement) {
    //   // this.myModal2.nativeElement.classList.remove('show');
    //   this.myModal2.nativeElement.style.display = 'none';
    // this.cdr.detectChanges();
    // }
    // // this.amountModel?.nativeElement.classList.add('show');
    // if (this.amountModel?.nativeElement) {
    //   this.amountModel.nativeElement.style.display = 'block';
    // }

    // this.cdr.detectChanges();
    const formData = new FormData();
    formData.append("ticket_id", this.ticketId);
    formData.append("user_id", this.ticketUserData.data.id);
    formData.append("tickets", this.count.toString());

    this.service
      .stripe_event_transaction_preview(formData)
      .pipe(
        first(),
        catchError((error) => {
          console.error("Error occurred:", error);
          return throwError(error);
        })
      )
      .subscribe((response: any) => {
        console.log(response);
        if (this.myModal2?.nativeElement) {
          this.myModal2.nativeElement.classList.remove("show");
          this.myModal2.nativeElement.style.display = "none";
          this.cdr.detectChanges();
        }
        this.transactionModel?.nativeElement.classList.add("show");
        if (this.transactionModel?.nativeElement) {
          this.transactionModel.nativeElement.style.display = "block";
        }

        this.transactionPreviewData = response.data;
        this.transactionData = response;
      });
  }

  saveAmountAndDesc() {
    this.amountDescForm.get("amount")?.setValue(this.ticketPrice * this.count);
  }

  url: any;
  finalSubmit() {
    // if (this.transactionModel?.nativeElement) {
    //   this.transactionModel.nativeElement.classList.remove('show');
    //   this.transactionModel.nativeElement.style.display = 'none';
    //   this.cdr.detectChanges();
    // }
console.log("this.ticketData",this.ticketUserData.data);


    this.url =
    // this.transactionData?.rytepay_web_collect_link + "user_id" +
      this.transactionData?.rytepay_web_collect_link +
      "?user_guest_id=" +
      this.ticketUserData?.data?.id +
      "&ticket_buy_id=" +
      this.buyTicketId +
      "&card_number=" +
      this.cardDetail.cardNumber +
      "&card_exp_month=" +
      this.cardDetailForm.value.month +
      "&card_exp_year=" +
      this.cardDetailForm.value.year +
      "&currency=" +
      this.transactionPreviewData?.user_currency +
      "&amount=" +
      this.transactionPreviewData?.amount_to_receive +
      "&is_web=" + 1;

    console.log("url123456",this.url);

    this.router.navigate([`/event_ticket_list/${this.ticketUserData.data.id}`]);

    this.sharedDataService.setData(this.url);

    console.log(" this.sharedDataService.setData(this.url);", this.sharedDataService.setData(this.url));
    


    // window.open(this.url, "_blank");
  }

  sumArrayValues(numbers: number[]): number {
    return numbers.reduce((total, currentValue) => total + currentValue, 0);
  }
}
