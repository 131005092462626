export const environment = {
    production: false,
    // url: 'http://103.218.110.31/lollicash-backend/lolliAPis',
    url: 'https://lollicash.com/lolliAPis',
    Google_Map_API_Key: 'AIzaSyDW3-WUm-n9gwtrwg5L3QPXm4v_g6bcqVE',
    firebaseConfig: {
        apiKey: "AIzaSyAriH2PannD4hx_qbF5dIr6_unfddHoHcI",
        authDomain: "lollicash.firebaseapp.com",
        projectId: "lollicash",
        storageBucket: "lollicash.appspot.com",
        messagingSenderId: "567677718067",
        appId: "1:567677718067:web:26bb07ccea6f665ee79d46",
        measurementId: "G-GP1WLWMMH7"
    },
    image_url: "https://lollicash.com/events/",
    platform: 'Web'
};