<video
  src="../../../../assets/LolliCash-Video.mp4"
  autoplay
  loop
  playsinline
  class="image-bg rounded-2"
></video>
<div
  class="modal fade" id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
  #myModal
>
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content bg-dark">
      <!-- <div class="modal-header">
                <h2 class="modal-title mb-0" id="staticBackdropLabel">New Event!!!</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closeModel()"></button>
            </div> -->
      <div class="modal-body p-0 pt-3">
        <h1 class="text-center text-danger">{{ eventData?.event_name }}</h1>
        <!-- <div class="text-center">
                    <img src="{{imageUrl + eventData?.image_url}}" alt="Event Image" width="100%" height="100%"
                        style="object-fit: cover;" />
                </div> -->
        <div id="eventCarousel" class="carousel slide" data-bs-ride="carousel"  data-bs-interval="5000">
          <div class="carousel-inner">
            <div
              *ngFor="let event of eventList; let i = index"
              class="carousel-item"
              [class.active]="i === 0"
            >
              <div class="text-center text-danger">
                <h1>{{ event.event_name }}</h1>
                <img
                  [src]="imageUrl + event.image_url"
                  alt="Event Image"
                  width="100%"
                  height="100%"
                  style="object-fit: cover"
                />
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#eventCarousel"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#eventCarousel"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            (click)="closeModel()"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-light"
            [disabled]="buttonDisabled"
            (click)="buyTicket()"
          >
            Buy a Ticket
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <section class="home-bg" style="padding-bottom: 110px;">
    <nav class="navbar">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">
                <img src="../../../assets/logo1.png" width="125px" alt="" class="d-inline-block align-text-top" />
            </a>
        </div>
    </nav>
    <div class="container" style="padding-top: 50px;">
        <div class="col-12 d-flex flex-wrap justify-content-between align-items-center">
            <div class="col-12 col-md-5">
                <div class="row justify-content-center align-items-center p-2">
                    <div class="card login-form">
                        <div class="card-body py-4">
                            <h2 class="card-title text-center my-4" style="font-size: 22px; color: #071E55 !important;">
                                Login to lolliCash</h2>
                            <div class="card-text">
                                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                    <div class="form-group py-3">
                                        <p-dropdown appendTo="body" [options]="countryCodes" optionValue="code"
                                            optionLabel="name" placeholder="Country Code" formControlName="countryISO"
                                            [filter]="true" styleClass="col-12 border-0 text-dark">
                                        </p-dropdown>
                                    </div>
                                    <div class="form-group py-3">
                                        <input type="text" class="form-control border-0" id="exampleInputNumber"
                                            aria-describedby="emailHelp" formControlName="mobileNo"
                                            placeholder="Enter Phone Number" minlength="10" maxlength="10">
                                    </div>
                                    <div class="form-group py-3">
                                        <input type="password" class="form-control border-0" formControlName="password"
                                            id="exampleInputPassword1" placeholder="Enter Password">
                                    </div>
                                    <div class="d-flex flex-wrap justify-content-between align-items-center py-4">
                                        <a class="text-dark h6" [routerLink]="['/sign_up']"
                                            style="cursor: pointer; text-decoration: none; font-weight: 600;">Don't have
                                            account? <span class="text-danger">Sign Up</span></a>
                                        <a class="text-danger h6" [routerLink]="['/forgot_password']"
                                            style="cursor: pointer; text-decoration: none; font-weight: 600;">Forgot
                                            password?</a>
                                    </div>
                                    <div class="col-12">
                                        <button type="submit" class="col-12 btn btn-danger my-4 py-2">Login</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <h1 class="text-light mb-4" style="text-shadow: 1px 1px #373737; font-size: 45px;">
                    We provide you with convenient Money Transfer & Wallet System
                </h1>
                <p class="text-light mb-4"
                    style="font-size: 22px; line-height: 30px; font-weight: 400; text-shadow: 1px 1px #373737;">
                    Send and Receive money from anywhere in the world with LolliCash. Send money for medical,
                    educational and receive payment from services and selling of merchandise.
                </p>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="section">
    <div class="container">
        <div class="row">
            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center">
                <div class="col-12 col-md-6 text-center first-image">
                    <img src="../../../assets/mobile.png" alt="Image 1" width="100%">
                </div>
                <div class="col-12 col-md-6" style="text-align: justify;">
                    <h2 class="text-center" style="color: #071E55 !important;">About LolliCash Wallet & Payment System.
                    </h2>
                    <p class="text-black">
                        LolliCash Wallet and Payment system is leading money transmitting payment technology which helps
                        in sending and receiving of money from one country to another. With a vision-driven strategy to
                        make both domestic and international money transfer easier and convenient worldwide.The scan and
                        pay technology make it easier for small scale businesses to efficiently conduct business
                        transaction. We purposely aim at the movement of money, a diverse culture of fintech innovation,
                        and customer-centric capabilities. No need to worry about having a Bank account to receive
                        money.
                    </p>
                    <p class="text-black">
                        LolliCash LLC leverages its modern, mobile, and API-driven platform and collaborates with the
                        world's leading brands to serve consumers through LolliCash Wallet system, its
                        direct-to-consumer digital business, and its global retail network.
                    </p>
                </div>
            </div>
            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center">
                <div class="col-12 col-md-6" style="text-align: justify;">
                    <h2 class="text-center" style="color: #071E55 !important;">Money Transfers & Receiving made easier
                        at your Fingertips.</h2>
                    <p class="text-black">
                        With the cutting edge and secure mobile application, you can send, receive, request, scan and
                        pay anyone, anywhere and at any time. Download LolliCash from Google Play and Apple Apps Store
                        Now!
                    </p>
                    <div class="mt-4 text-center">
                        <a class="btn btn-dark me-2" href="https://apps.apple.com/us/app/lollicash/id6444011906"
                            target="_blank">
                            <div class="d-flex flex-wrap justify-content-between align-items-center">
                                <div>
                                    <img src="../../../../assets/app-store.png" alt="">
                                </div>
                                <div class="ms-2">
                                    <span style="font-size: 12px;">Download on the</span>
                                    <p class="text-light font-weight-normal mb-0"
                                        style="font-size: 17px; margin-top: -4px;">App Store
                                    </p>
                                </div>
                            </div>
                        </a>
                        <a class="btn btn-dark"
                            href="https://play.google.com/store/apps/details?id=com.lollicash&pcampaignid=web_share"
                            target="_blank">
                            <div class="d-flex flex-wrap justify-content-between align-items-center">
                                <div>
                                    <img src="../../../../assets/google-play.png" alt="">
                                </div>
                                <div class="ms-2">
                                    <span style="font-size: 12px;">Download on the</span>
                                    <p class="text-light font-weight-normal mb-0"
                                        style="font-size: 17px; margin-top: -4px;">Play Store
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-md-6 text-center">
                    <img src="../../../assets/LC.png" alt="Image 2" width="100%">
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="section">
    <div class="container">
        <div class="row">
            <div class="mb-4">
                <div class="security_image text-center" style="margin-bottom: 35px;">
                    <img src="../../../assets/security.png" alt="Security" class="img-fluid"
                        style="height: auto; object-fit: cover;">
                </div>
                <div class="text-center">
                    <p class="text-black">It's never been easier to send money, but that doesn't mean it shouldn't be
                        safe. Lollicash
                        utilizes a variety of safety features to process millions of payments a year and ensure the
                        protection of our customers.</p>
                </div>
            </div>
            <div class="col-12 d-flex flex-wrap justify-content-center align-items-center">
                <div class="col-md-6">
                    <img src="../../../assets/security_bg.jpg" style="width: 100%;" alt="">
                </div>
                <div class="col-md-6 px-4">
                    <div class="d-flex flex-wrap justify-content-start align-items-center mb-3">
                        <span style="font-size: 35px;">
                            <i class="fi fi-sr-user-lock"></i>
                        </span>
                        <div class="ms-3">
                            <h2 class="mb-1" style="color: #071E55;">Security Locks</h2>
                            <p class="mb-0">PIN entry, Touch ID, or Face ID verification protects payments.</p>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-start align-items-center mb-3">
                        <span style="font-size: 35px;">
                            <i class="fi fi-rs-binary-circle-check"></i>
                        </span>
                        <div class="ms-3">
                            <h2 class="mb-1" style="color: #071E55;">Encryption</h2>
                            <p class="mb-0">PCI-DSS level 1 certification means we protect all your data.</p>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-start align-items-center mb-3">
                        <span style="font-size: 35px;">
                            <i class="fi fi-sr-user-shield"></i>
                        </span>
                        <div class="ms-3">
                            <h2 class="mb-1" style="color: #071E55;">Account Protection</h2>
                            <p class="mb-0">We provide 24/7 hrs account protection.</p>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-start align-items-center mb-3">
                        <span style="font-size: 35px;">
                            <i class="fi fi-ss-shield-keyhole"></i>
                        </span>
                        <div class="ms-3">
                            <h2 class="mb-1" style="color: #071E55;">Account Protection</h2>
                            <p class="mb-0">Pause card spending instantly when you leave it somewhere.</p>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-start align-items-center mb-3">
                        <span style="font-size: 35px;">
                            <i class="fi fi-rr-pause"></i>
                        </span>
                        <div class="ms-3">
                            <h2 class="mb-1" style="color: #071E55;">Account Notification</h2>
                            <p class="mb-0">Push, email, and text notifications alert you of any account usage.</p>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-start align-items-center mb-3">
                        <span style="font-size: 35px;">
                            <i class="fi fi-br-key"></i>
                        </span>
                        <div class="ms-3">
                            <h2 class="mb-1" style="color: #071E55;">Fraud Protection</h2>
                            <p class="mb-0">We'll help protect you from unauthorized charges.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="section">
    <div class="container">
        <div class="row">
            <div class="text-center" style="margin-bottom: 35px;">
                <h2 style="color: #071E55;">Lollicash Customer FAQ</h2>
            </div>
            <ul class="nav nav-tabs justify-content-center mb-2" id="myTab" role="tablist" style="border-bottom: 0;">
                <li class="nav-item" role="presentation" style="font-size: 18px;">
                    <button class="nav-link active px-2" id="sendMoney-tab" data-bs-toggle="tab"
                        data-bs-target="#sendMoney" type="button" role="tab" aria-controls="sendMoney"
                        aria-selected="true">Sending Money</button>
                </li>
                <li class="nav-item" role="presentation" style="font-size: 18px;">
                    <button class="nav-link px-2" id="ReceiveMoney-tab" data-bs-toggle="tab"
                        data-bs-target="#ReceiveMoney" type="button" role="tab" aria-controls="ReceiveMoney"
                        aria-selected="false">Receiving
                        Money</button>
                </li>
                <li class="nav-item" role="presentation" style="font-size: 18px;">
                    <button class="nav-link px-2" id="CancelMoney-tab" data-bs-toggle="tab"
                        data-bs-target="#CancelMoney" type="button" role="tab" aria-controls="CancelMoney"
                        aria-selected="false">Canceling Money
                        transfer</button>
                </li>
                <li class="nav-item" role="presentation" style="font-size: 18px;">
                    <button class="nav-link px-2" id="ManageMoney-tab" data-bs-toggle="tab"
                        data-bs-target="#ManageMoney" type="button" role="tab" aria-controls="ManageMoney"
                        aria-selected="false">Managing My
                        Account</button>
                </li>
                <li class="nav-item" role="presentation" style="font-size: 18px;">
                    <button class="nav-link px-2" id="TechSupport-tab" data-bs-toggle="tab"
                        data-bs-target="#TechSupport" type="button" role="tab" aria-controls="TechSupport"
                        aria-selected="false">Technical
                        Support</button>
                </li>
                <li class="nav-item" role="presentation" style="font-size: 18px;">
                    <button class="nav-link px-2" id="fraud-tab" data-bs-toggle="tab" data-bs-target="#fraud"
                        type="button" role="tab" aria-controls="fraud" aria-selected="false">Fraud Prevention</button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="sendMoney" role="tabpanel" aria-labelledby="sendMoney-tab">
                    <div class="accordion accordion-flush mt-4" id="accordionFlushExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading1">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse1" aria-expanded="false"
                                    aria-controls="flush-collapse1">
                                    How can I estimate fees before I start a money transfer?
                                </button>
                            </h2>
                            <div id="flush-collapse1" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body pt-0">
                                    <p>
                                        Go to Track & Receive on the homepage to estimate transfer costs and see current
                                        exchange rates. You can estimate fees for sending online or via the app.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading2">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse2" aria-expanded="false"
                                    aria-controls="flush-collapse2">
                                    How do I track the status of a transfer?
                                </button>
                            </h2>
                            <div id="flush-collapse2" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <p>
                                        There are two ways to track the status of your transfer: - If you have an online
                                        profile, log in and view your Transaction History. - If you don’t have an online
                                        profile, enter your reference/authorization number and your last name on our
                                        Track & Receive tool.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading3">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse3" aria-expanded="false"
                                    aria-controls="flush-collapse3">
                                    Are there limits to how much or how often I can send money?
                                </button>
                            </h2>
                            <div id="flush-collapse3" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <p>
                                        For your protection, we have a variety of rules in place to limit how much money
                                        and how many transfers you can complete on a daily, weekly, monthly and yearly
                                        basis.
                                    </p>
                                    <p>
                                        These limits aren’t always the same. They fluctuate based on market trends,
                                        security needs and current laws and regulations, so the list of limits isn’t
                                        available for customers.
                                    </p>
                                    <p>
                                        However, if you ever receive a limit error when you’re trying to send money, you
                                        can contact our customer care team for further assistance.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading4">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse4" aria-expanded="false"
                                    aria-controls="flush-collapse4">
                                    How can I schedule a recurring send?
                                </button>
                            </h2>
                            <div id="flush-collapse4" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <p>
                                        You can automatically send money to a recipient on a weekly or monthly basis by
                                        setting up a recurring send. To get started, select a past send under
                                        'Transaction History' on your account home page. For detailed instructions,
                                        please read how to schedule a recurring send.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading5">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse5" aria-expanded="false"
                                    aria-controls="flush-collapse5">
                                    Why didn’t my scheduled transfer go through?
                                </button>
                            </h2>
                            <div id="flush-collapse5" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <p>
                                        There are a few possible reasons your scheduled send failed. Based on the error
                                        message you received, take one of the following steps to start sending again:
                                    </p>
                                    <p>
                                        Identity Reauthentication: For your security, we periodically require you to
                                        reauthenticate your identity to ensure your personal and financial safety and
                                        prevent fraud. To get started, log in to your LolliCash profile and start a new,
                                        one-time transfer to your receiver. During this process, you’ll be asked to
                                        upload a new ID. After this transfer completes, your recurring transaction will
                                        start up again without any further action from you.
                                    </p>
                                    <p>
                                        Expired Identification: If your photo ID has expired, you’ll need to upload a
                                        new one. To get started, log in to your LolliCash profile and start a new,
                                        one-time transfer to your receiver. During this process, you’ll be asked to
                                        upload a new ID. After this transfer completes, your recurring transaction will
                                        start up again without any further action from you.
                                    </p>
                                    <p>
                                        System Error: Log in to your LolliCash online profile and create a new, one-time
                                        transfer to your receiver. If this transaction is successful, your recurring
                                        transaction will start up again without any further action from you.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading6">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse6" aria-expanded="false"
                                    aria-controls="flush-collapse6">
                                    Which countries can I send money to?
                                </button>
                            </h2>
                            <div id="flush-collapse6" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading6" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <p>
                                        There are more than 200 countries and territories you can send to using
                                        LolliCash. To see which countries you can send to, simply start sending money or
                                        estimating fees.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading7">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse7" aria-expanded="false"
                                    aria-controls="flush-collapse7">
                                    How much money can I send online?
                                </button>
                            </h2>
                            <div id="flush-collapse7" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading7" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <p>
                                        For most countries, you can send up to $10,000.00 per online transfer, and up to
                                        $10,000.00 every 30 calendar days. If necessary, you may send additional funds
                                        from a LolliCash agent location.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading8">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse8" aria-expanded="false"
                                    aria-controls="flush-collapse8">
                                    What are the fees to send money?
                                </button>
                            </h2>
                            <div id="flush-collapse8" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading8" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <p>
                                        Fees vary depending on where you are sending, how much, and how you are paying.
                                        Fees are typically lower if you pay with a bank account (must be a U.S. checking
                                        account). If you pay with your credit or debit card, the fees are slightly
                                        higher. Go to Rates and Fees to see how much a transfer will cost.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading9">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse9" aria-expanded="false"
                                    aria-controls="flush-collapse9">
                                    Can I send money to someone’s bank account or mobile wallet?
                                </button>
                            </h2>
                            <div id="flush-collapse9" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading9" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <p>
                                        LolliCash allows you to send money directly to a bank account or mobile wallet
                                        in select countries. To find out if you can send money to your receiver's bank
                                        account or mobile wallet, start sending money or estimate fees from the
                                        homepage, and select “Direct to Bank Account or Account Deposit” as your receive
                                        option. Service availability may vary at agent locations.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading10">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse10" aria-expanded="false"
                                    aria-controls="flush-collapse10">
                                    When will my receiver’s money be available in their bank account?
                                </button>
                            </h2>
                            <div id="flush-collapse10" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading10" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <p>
                                        Many transfers to bank accounts are completed within a few hours. Timing of
                                        funds availability in your receiver's account varies depending on destination
                                        country and is subject to banking hours and systems availability, good funds
                                        availability, approval by LolliCash’s verification systems and local laws and
                                        regulations; banking business hours typically exclude weekends/holidays. See the
                                        table below for more details. Available in select countries.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading11">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse11" aria-expanded="false"
                                    aria-controls="flush-collapse11">
                                    When will my receiver’s money be available for cash pickup?
                                </button>
                            </h2>
                            <div id="flush-collapse11" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading11" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <p>
                                        Money is typically ready for cash pickup within minutes after the transfer has
                                        been sent successfully. Regardless of the payment method used, timing is subject
                                        to operating hours, the destination country, availability, local laws and
                                        regulations and compliance requirements.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading12">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse12" aria-expanded="false"
                                    aria-controls="flush-collapse12">
                                    What payment methods can I use for sending money online?
                                </button>
                            </h2>
                            <div id="flush-collapse12" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading12" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <p>
                                        You can pay for online transfers using a credit or debit card (Visa or
                                        MasterCard). Card issuer cash advance fee and associated interest charges may
                                        apply when you use a credit card.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading13">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse13" aria-expanded="false"
                                    aria-controls="flush-collapse13">
                                    When adding a new payment method online, which address do I need to use?
                                </button>
                            </h2>
                            <div id="flush-collapse13" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading13" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <p>
                                        You need to use the billing address associated with the bank account or card you
                                        are using to pay. LolliCash currently allows only one billing address on file.
                                        This address will be associated with all your payment methods.
                                    </p>
                                    <p>
                                        To update your address, log in to your LolliCash online profile and visit the
                                        ‘Payment Methods’ section.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading14">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse14" aria-expanded="false"
                                    aria-controls="flush-collapse14">
                                    What payment methods can I use when sending money at an agent location?
                                </button>
                            </h2>
                            <div id="flush-collapse14" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading14" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <p>
                                        In most cases, cash is the only form of payment accepted for transactions
                                        completed at an agent location. You will need to bring enough cash to cover the
                                        transfer amount and any additional fees.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading15">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse15" aria-expanded="false"
                                    aria-controls="flush-collapse15">
                                    Does the receiver’s name have to match their government-issued ID exactly?
                                </button>
                            </h2>
                            <div id="flush-collapse15" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading15" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <p>
                                        For security reasons, many agent locations will not pay out a transfer if the
                                        name on the receiver's identification does not match exactly. Please confirm
                                        your receiver’s name as it appears on their ID. If your receiver is still having
                                        this problem.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading16">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse16" aria-expanded="false"
                                    aria-controls="flush-collapse16">
                                    Can my receiver pick up their transfer in a different country than I sent it to?
                                </button>
                            </h2>
                            <div id="flush-collapse16" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading16" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <p>
                                        No. For security reasons, your receiver must pick up the funds in the country
                                        you sent to. If your receiver is not in the country you sent to, please cancel
                                        the transaction and send again to the new destination.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading17">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse17" aria-expanded="false"
                                    aria-controls="flush-collapse17">
                                    Can I send money by calling LolliCash Customer Service?
                                </button>
                            </h2>
                            <div id="flush-collapse17" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading17" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <p>
                                        This service is currently not available. You can send money online, with our
                                        mobile app, or in person at a LolliCash agent location.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="ReceiveMoney" role="tabpanel" aria-labelledby="ReceiveMoney-tab">
                    <div class="accordion accordion-flush mt-4" id="receiveMoneyFlush">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading1">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-receive1" aria-expanded="false"
                                    aria-controls="flush-receive1">
                                    What are the options to receive money?
                                </button>
                            </h2>
                            <div id="flush-receive1" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading1" data-bs-parent="#receiveMoneyFlush">
                                <div class="accordion-body pt-0">
                                    <p>
                                        LolliCash offers different ways to receive money around the world, depending on
                                        your send country, including:
                                    </p>
                                    <p>
                                        Cash Pickup: Cash can be picked up at any LolliCash agent location. The money is
                                        typically ready for pickup in as little as 10 minutes after the transfer is
                                        sent. Regardless of the payment method used, timing is subject to operating
                                        hours, the destination country, availability, local laws and regulations and
                                        compliance requirements. Available in all countries.
                                    </p>
                                    <p>
                                        Bank Account: You can send money directly to your receiver's bank account by
                                        entering your receiver's bank account information. Or, in eligible countries,
                                        you can give us your receiver's mobile phone number, and they'll receive a text
                                        asking them to enter their own account information. Many send-to-account
                                        transfers complete within a few hours.Timing of funds availability in your
                                        receiver's account varies depending on country and is subject to banking hours
                                        and systems availability, good funds availability, approval by the LolliCash
                                        verification systems and local laws and regulations; banking business typically
                                        excludes weekends/holidays. See the table below for more details. Available in
                                        select countries.
                                    </p>
                                    <p>
                                        Debit Card: You can send money directly to your receiver's debit card by
                                        entering your receiver's debit card information. Or, in eligible countries, you
                                        can give us your receiver's mobile phone number, and they'll receive a text
                                        asking them to enter their own card information. The money is sent to the card,
                                        typically within minutes after the transfer is submitted and successfully
                                        processed; subject to banking and system availability and compliance with
                                        regulatory requirements. Available in select countries.
                                    </p>
                                    <p>
                                        Mobile Wallets: You can send money directly to your receiver's mobile wallet
                                        account. The money is typically sent to the account after the transfer is
                                        submitted and successfully processed. The credit will be applied to the
                                        receiver's mobile wallet account associated with the mobile number specified by
                                        the sender. Transfers to mobile wallets are available online and at LolliCash
                                        agent locations. Timing is subject to local laws and regulations and compliance
                                        requirements. Additional conditions may apply. Please refer to the mobile wallet
                                        service terms and conditions. Available in select countries.
                                    </p>
                                    <p>
                                        Home Delivery: You can send money to be delivered in person to the receiver's
                                        home address. The money will be delivered by a local LolliCash agent. Timing is
                                        subject to agent operating hours, local laws and regulations and compliance
                                        requirements. Available in select countries.
                                    </p>
                                    <p>
                                        FastSend: FastSend sends your receiver a text when you’ve sent them money. When
                                        they click on the link in that text, they choose how to deposit the money into
                                        their bank account, using either their debit card information or account number
                                        and information, depending on their country. Timing is subject to local laws and
                                        regulations and compliance requirements.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading2">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-receive2" aria-expanded="false"
                                    aria-controls="flush-receive2">
                                    What information do I need to pick up money that was sent to me?
                                </button>
                            </h2>
                            <div id="flush-receive2" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading2" data-bs-parent="#receiveMoneyFlush">
                                <div class="accordion-body">
                                    <p>
                                        When picking up cash, you will need the Reference Number for the transaction
                                        along with a valid photo ID. The person who sent you money should have the
                                        Reference Number. Some countries also require a valid proof of address when an
                                        ID does not contain an address.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading3">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-receive3" aria-expanded="false"
                                    aria-controls="flush-receive3">
                                    How can I find an agent location to pick up money that was sent to me?
                                </button>
                            </h2>
                            <div id="flush-receive3" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading3" data-bs-parent="#receiveMoneyFlush">
                                <div class="accordion-body">
                                    <p>
                                        Go to our Find a Location tool to search for LolliCash agent locations
                                        worldwide.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading4">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-receive4" aria-expanded="false"
                                    aria-controls="flush-receive4">
                                    What are the currency options when receiving money?
                                </button>
                            </h2>
                            <div id="flush-receive4" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading4" data-bs-parent="#receiveMoneyFlush">
                                <div class="accordion-body">
                                    <p>
                                        For certain countries, LolliCash allows the sender to choose the currency in
                                        which the receiver can receive money. This option will appear when you are
                                        sending money or estimating fees. Payout in a choice of currencies is available
                                        only at select receive locations and is subject to availability of that currency
                                        at time of collection.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading5">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-receive5" aria-expanded="false"
                                    aria-controls="flush-receive5">
                                    Why was I denied funds at the pickup location?
                                </button>
                            </h2>
                            <div id="flush-receive5" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading5" data-bs-parent="#receiveMoneyFlush">
                                <div class="accordion-body">
                                    <p>
                                        The most common reasons for being denied funds are:
                                    </p>
                                    <p>
                                        - The transaction has not yet been processed. Visit the Track & Receive tool to
                                        find out the status of a transfer. - You did not have sufficient identification.
                                        - You did not visit a LolliCash agent location. Find a Location.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading6">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-receive6" aria-expanded="false"
                                    aria-controls="flush-receive6">
                                    What happened to the LolliCash FastSend™ receive method?
                                </button>
                            </h2>
                            <div id="flush-receive6" class="accordion-collapse collapse"
                                aria-labelledby="flush-heading6" data-bs-parent="#receiveMoneyFlush">
                                <div class="accordion-body">
                                    <p>
                                        As a part of always striving to improve your experience, we made some changes to
                                        FastSend to help make it even faster, better and more convenient.
                                    </p>
                                    <p>
                                        You can now access FastSend by selecting either "Debit Card Deposit" or "Bank
                                        Account" as your receive method.
                                    </p>
                                    <p>
                                        If you're sending to an eligible FastSend country, you'll be asked if you want
                                        to input your receiver's mobile phone number (to make it a FastSend transfer) or
                                        if you'd prefer to enter your receiver's card or account information yourself.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="CancelMoney" role="tabpanel" aria-labelledby="CancelMoney-tab">
                    <div class="accordion accordion-flush mt-4" id="CancelMoneyFlush">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading1">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-cancel1" aria-expanded="false" aria-controls="flush-cancel1">
                                    How do I cancel a money transfer?
                                </button>
                            </h2>
                            <div id="flush-cancel1" class="accordion-collapse collapse" aria-labelledby="flush-heading1"
                                data-bs-parent="#CancelMoneyFlush">
                                <div class="accordion-body pt-0">
                                    <p>
                                        There are multiple ways to cancel a money transfer: If you sent money online or
                                        in our app, log in to your LolliCash online profile and look for the transfer in
                                        your Transaction History. You can cancel it on the Transaction Details page.If
                                        you sent the transaction at an agent location, you can cancel it online here or
                                        by using our self-service portal and selecting 'Cancel a Transfer'. You can also
                                        return to the same agent location or by calling us at 1-800-926-9400. Please
                                        note that transfers can’t be canceled if the receiver has already collected the
                                        funds or had funds deposited into their account.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading2">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-cancel2" aria-expanded="false" aria-controls="flush-cancel2">
                                    How can I get a refund?
                                </button>
                            </h2>
                            <div id="flush-cancel2" class="accordion-collapse collapse" aria-labelledby="flush-heading2"
                                data-bs-parent="#CancelMoneyFlush">
                                <div class="accordion-body">
                                    <p>
                                        To get a refund, you’ll need to cancel your transfer. There are two ways to
                                        cancel a transfer: If you sent money online or in our app, log in to your
                                        LolliCash online profile and look for the transfer in your Transaction History.
                                        You can cancel it on the Transaction Details page. If you sent the transaction
                                        at an agent location, you can cancel it online here or by using our self-service
                                        portal and selecting 'Cancel a Transfer'. You can also return to the same agent
                                        location or by calling us at <b> 1-800-926-9400.</b>
                                    </p>
                                    <p>
                                        If you cancel within 1 hour of payment, you will be issued a full refund. For
                                        cancellations made more than 1 hour and up to 180 days after payment, you will
                                        be issued a refund for the send amount only, excluding fees.
                                    </p>
                                    <p>
                                        Please note that transfers can’t be canceled if the receiver has already
                                        collected the funds or had funds deposited into their account.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading3">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-cancel3" aria-expanded="false" aria-controls="flush-cancel3">
                                    How do I edit my transfer details or receiver’s name?
                                </button>
                            </h2>
                            <div id="flush-cancel3" class="accordion-collapse collapse" aria-labelledby="flush-heading3"
                                data-bs-parent="#CancelMoneyFlush">
                                <div class="accordion-body">
                                    <p>
                                        If you need to make a small edit to your receiver’s name so that they can pick
                                        up their money, you can do so online here or by using our self-service portal
                                        and selecting 'Edit a Transfer'. If you need to make a different edit to your
                                        transfer, you’ll need to cancel it for a refund and start a new one.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading4">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-cancel4" aria-expanded="false" aria-controls="flush-cancel4">
                                    How long will it take to receive my refund for a canceled transfer?
                                </button>
                            </h2>
                            <div id="flush-cancel4" class="accordion-collapse collapse" aria-labelledby="flush-heading4"
                                data-bs-parent="#CancelMoneyFlush">
                                <div class="accordion-body">
                                    <p>
                                        If you cancel the money transfer online or via phone, LolliCash will transmit
                                        the credit to the card issuer within 3 days but it could take the funds longer
                                        to appear on your credit/debit card or bank account.If you cancel the money
                                        transfer at the agent location where you sent the money transfer, you will
                                        receive the refund in cash.Transactions cannot be canceled and refunded if the
                                        funds have been collected by the receiver or already deposited into a receiver’s
                                        account.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="ManageMoney" role="tabpanel" aria-labelledby="ManageMoney-tab">
                    <div class="accordion accordion-flush mt-4" id="manageAccountFlush">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading1">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-manage1" aria-expanded="false" aria-controls="flush-manage1">
                                    How do I change my password?
                                </button>
                            </h2>
                            <div id="flush-manage1" class="accordion-collapse collapse" aria-labelledby="flush-heading1"
                                data-bs-parent="#manageAccountFlush">
                                <div class="accordion-body pt-0">
                                    <p>
                                        Log into your online profile. Then, select “View Profile Info”, and click the
                                        edit icon next to your password.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading2">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-manage2" aria-expanded="false" aria-controls="flush-manage2">
                                    How do I reset my password?
                                </button>
                            </h2>
                            <div id="flush-manage2" class="accordion-collapse collapse" aria-labelledby="flush-heading2"
                                data-bs-parent="#manageAccountFlush">
                                <div class="accordion-body">
                                    <p>
                                        Select the ‘Forgot Password’ link on the log in screen. From here, follow the
                                        instructions to reset your password. You will be asked to request a one-time
                                        security code which will be emailed to you. The one-time security code will
                                        allow you to reset your password.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading3">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-manage3" aria-expanded="false" aria-controls="flush-manage3">
                                    How do I unlock my LolliCash profile?
                                </button>
                            </h2>
                            <div id="flush-manage3" class="accordion-collapse collapse" aria-labelledby="flush-heading3"
                                data-bs-parent="#manageAccountFlush">
                                <div class="accordion-body">
                                    <p>
                                        To unlock your online profile, click or tap “Log in” on our website or app.
                                        Then, select “Forgot Password?” toward the bottom of the screen. You’ll be
                                        prompted to enter the email address associated with your profile. Then, check
                                        your inbox for instructions on how to reset your password.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading4">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-manage4" aria-expanded="false" aria-controls="flush-manage4">
                                    How do I set my preferences for receiving email notifications from LolliCash?
                                </button>
                            </h2>
                            <div id="flush-manage4" class="accordion-collapse collapse" aria-labelledby="flush-heading4"
                                data-bs-parent="#manageAccountFlush">
                                <div class="accordion-body">
                                    <p>
                                        Log into your online profile and select ’View Profile Info.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading5">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-manage5" aria-expanded="false" aria-controls="flush-manage5">
                                    How do I update my payment methods?
                                </button>
                            </h2>
                            <div id="flush-manage5" class="accordion-collapse collapse" aria-labelledby="flush-heading5"
                                data-bs-parent="#manageAccountFlush">
                                <div class="accordion-body">
                                    <p>
                                        Log into your online profile. Then, select 'View Profile Info', and click the
                                        edit icon next to your payment methods.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading6">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-manage6" aria-expanded="false" aria-controls="flush-manage6">
                                    How do I update my personal information?
                                </button>
                            </h2>
                            <div id="flush-manage6" class="accordion-collapse collapse" aria-labelledby="flush-heading6"
                                data-bs-parent="#manageAccountFlush">
                                <div class="accordion-body">
                                    <p>
                                        Log into your online profile. Then, select 'View Profile Info', and click the
                                        edit icon next to your personal information.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="TechSupport" role="tabpanel" aria-labelledby="TechSupport-tab">
                    <div class="accordion accordion-flush mt-4" id="techSupportFlush">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading1">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-tech1" aria-expanded="false" aria-controls="flush-tech1">
                                    I'm having trouble getting your website to work. What can I do to fix it?
                                </button>
                            </h2>
                            <div id="flush-tech1" class="accordion-collapse collapse" aria-labelledby="flush-heading1"
                                data-bs-parent="#techSupportFlush">
                                <div class="accordion-body pt-0">
                                    <p>
                                        If you're having technical issues, browser issues or are getting an error
                                        message while using our website, try the following: Use a supported browser,
                                        including Chrome, Internet Explorer, Firefox and Safari. If you're already using
                                        one of these and are still having trouble, try a different one from the list.
                                        Open our website in a private or incognito window. (Learn how in the How do I
                                        use a private or incognito window? question below.) Clear your cookies. (Learn
                                        how in the How do I clear my cookies? question below.) Open our website on
                                        another device or wireless network (if available). Use our mobile app instead.
                                        (Download it on the App Store or Google Play.)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading2">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-tech2" aria-expanded="false" aria-controls="flush-tech2">
                                    How do I clear my cookies on a computer?
                                </button>
                            </h2>
                            <div id="flush-tech2" class="accordion-collapse collapse" aria-labelledby="flush-heading2"
                                data-bs-parent="#techSupportFlush">
                                <div class="accordion-body">
                                    <p>
                                        Scroll down to find your browser and follow the relevant instructions below:
                                        Chrome At the top right of your browser window, click More Select Settings and
                                        Clear Browsing Data Select All time to delete all the cookies Under the Cookies
                                        section, click Cookies and site data Click Clear Data Firefox Click the menu
                                        button and select Options Click Privacy & Security Under the Cookies and Site
                                        Data section, click Clear Data Select Cookies and Site Data Click the Clear
                                        button Safari Click Preferences Click Privacy and Details (all cookies should
                                        display) Click Remove All Internet Explorer Click the Tools button Select Safety
                                        and Delete browsing history Make sure Cookies and website data are selected
                                        Click Delete
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading3">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-tech3" aria-expanded="false" aria-controls="flush-tech3">
                                    How do I clear my cookies on a mobile device?
                                </button>
                            </h2>
                            <div id="flush-tech3" class="accordion-collapse collapse" aria-labelledby="flush-heading3"
                                data-bs-parent="#techSupportFlush">
                                <div class="accordion-body">
                                    <p>
                                        Scroll down to find your browser and operating system and follow the relevant
                                        instructions below: Chrome (Android) Tap More and select Settings Tap Privacy
                                        and Clear Browsing Data Choose the time range, i.e., Last hour or All time Check
                                        the Cookies, Media Licenses and Site Data boxes Tap Clear Data and Clear Chrome
                                        (iOS) At the bottom right, click More and select Settings Tap Privacy and Clear
                                        Browsing Data Check the Cookies and Site Data boxes Tap Clear Browsing Data and
                                        Clear Browsing Data Tap Done Safari (iOS) In your phone's settings, tap Safari
                                        Tap Advanced and Website Data Select Remove All Website Data Tap Confirm
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading4">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-tech4" aria-expanded="false" aria-controls="flush-tech4">
                                    How do I use a private or incognito window?
                                </button>
                            </h2>
                            <div id="flush-tech4" class="accordion-collapse collapse" aria-labelledby="flush-heading4"
                                data-bs-parent="#techSupportFlush">
                                <div class="accordion-body">
                                    <p>
                                        Scroll down to find your browser and follow the relevant instructions below:
                                        Chrome At the top right of your browser window, click More and select New
                                        Incognito Window A new window will appear with the Incognito icon in the top
                                        corner Firefox Click the menu button Click New Private Window The Private
                                        Browsing homepage will open in a new window Safari At the top of your browser
                                        window, click File and select New Private Window A new window will appear with a
                                        dark Smart Search field and white text Internet Explorer Click the gear icon in
                                        the upper-right corner Select Safety Click InPrivate Browsing IE will indicate
                                        it's in InPrivate mode from the blue box next to the location bar, with the
                                        label InPrivate
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="fraud" role="tabpanel" aria-labelledby="fraud-tab">
                    <div class="accordion accordion-flush mt-4" id="fraudFlush">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading1">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-fraud1" aria-expanded="false" aria-controls="flush-fraud1">
                                    I received an email from LolliCash asking for my financial information. Should I
                                    provide it?
                                </button>
                            </h2>
                            <div id="flush-fraud1" class="accordion-collapse collapse" aria-labelledby="flush-heading1"
                                data-bs-parent="#fraudFlush">
                                <div class="accordion-body pt-0">
                                    <p>
                                        No. LolliCash will never send you an unsolicited email asking for your personal
                                        or financial information. You should only submit this information if you are
                                        logged into your LolliCash online profile at LolliCash.com. The safest way to do
                                        this is to type the URL into your browser rather than clicking on a link
                                        embedded in an unsolicited email. If you receive a suspicious email purporting
                                        to be from LolliCash, please report it so we can investigate. Also, if your
                                        financial information has been compromised, we recommend that you contact your
                                        financial institution immediately.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading2">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-fraud2" aria-expanded="false" aria-controls="flush-fraud2">
                                    What can I do if my receiver or I have been the victim of a fraud perpetrator?
                                </button>
                            </h2>
                            <div id="flush-fraud2" class="accordion-collapse collapse" aria-labelledby="flush-heading2"
                                data-bs-parent="#fraudFlush">
                                <div class="accordion-body">
                                    <p>
                                        Please call LolliCash Customer Service at 1-800-926-9400 or complete our report
                                        fraud form online.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading3">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-fraud3" aria-expanded="false" aria-controls="flush-fraud3">
                                    Where can I get more information on protecting myself from consumer fraud?
                                </button>
                            </h2>
                            <div id="flush-fraud3" class="accordion-collapse collapse" aria-labelledby="flush-heading3"
                                data-bs-parent="#fraudFlush">
                                <div class="accordion-body">
                                    <p>
                                        To learn more about how to protect yourself from fraud, visit our Consumer
                                        Protection page.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading4">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-fraud4" aria-expanded="false" aria-controls="flush-fraud4">
                                    Why do you authenticate my identity?
                                </button>
                            </h2>
                            <div id="flush-fraud4" class="accordion-collapse collapse" aria-labelledby="flush-heading4"
                                data-bs-parent="#fraudFlush">
                                <div class="accordion-body">
                                    <p>
                                        We authenticate your identity to ensure your personal and financial safety and
                                        to prevent fraud.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading5">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-fraud5" aria-expanded="false" aria-controls="flush-fraud5">
                                    What is phishing?
                                </button>
                            </h2>
                            <div id="flush-fraud5" class="accordion-collapse collapse" aria-labelledby="flush-heading5"
                                data-bs-parent="#fraudFlush">
                                <div class="accordion-body">
                                    <p>
                                        Phishing is a type of online fraud designed to steal your personal information
                                        such as user names, passwords, credit card details or secret questions and
                                        answers on a fake LolliCash website. Phishing is typically carried out by e-mail
                                        and is disguised to appear as a legitimate e-mail from LolliCash. Links in these
                                        emails direct you to a fake website that looks like the legitimate LolliCash
                                        online site.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading6">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-fraud6" aria-expanded="false" aria-controls="flush-fraud6">
                                    How do I prevent phishing?
                                </button>
                            </h2>
                            <div id="flush-fraud6" class="accordion-collapse collapse" aria-labelledby="flush-heading6"
                                data-bs-parent="#fraudFlush">
                                <div class="accordion-body">
                                    <p>
                                        The best way to prevent becoming a victim of phishing is to be aware of common
                                        scams. Also, remember to carefully review messages you receive to see if it is a
                                        phishing scam. Are words misspelled (English is often a second language for
                                        phishers, so misspellings and poor grammar can be key indicators) and do links
                                        within the email really take you to the correct site? And, by following the
                                        notion that if something seems too good to be true, it likely is.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading7">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-fraud7" aria-expanded="false" aria-controls="flush-fraud7">
                                    How do I recognize phishing?
                                </button>
                            </h2>
                            <div id="flush-fraud7" class="accordion-collapse collapse" aria-labelledby="flush-heading7"
                                data-bs-parent="#fraudFlush">
                                <div class="accordion-body">
                                    <p>
                                        Here are some things to look for in a phishing e-mail: Links to a website that
                                        ask you to verify your account information Links to a website that ask you for
                                        your bank account or credit card numbers, usernames and passwords Threats that
                                        if you don't verify your account information, your account will be shut down
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-heading8">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#flush-fraud8" aria-expanded="false" aria-controls="flush-fraud8">
                                    What should I do if I may have been a victim of phishing?
                                </button>
                            </h2>
                            <div id="flush-fraud8" class="accordion-collapse collapse" aria-labelledby="flush-heading8"
                                data-bs-parent="#fraudFlush">
                                <div class="accordion-body">
                                    <p>
                                        If you're suspicious that you have received a phishing email, don't second guess
                                        yourself — please report it so we can investigate.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
