import { Component, OnInit } from '@angular/core';
import { FooterComponent } from '../nav/footer/footer.component';
import { NavBarComponent } from '../nav/nav-bar/nav-bar.component';
import { DropdownModule } from 'primeng/dropdown';
import { first } from 'rxjs';
import { ApiService } from '../../service/api.service';

@Component({
  selector: 'app-deposit-money',
  standalone: true,
  imports: [NavBarComponent, FooterComponent, DropdownModule],
  templateUrl: './deposit-money.component.html',
  styleUrl: './deposit-money.component.css'
})
export class DepositMoneyComponent implements OnInit {

  constructor(private service: ApiService) { }

  countryCodes: any = [];

  ngOnInit(): void {
    this.service.getCountriesCode().pipe(first()).subscribe((response: any) => {
      this.countryCodes = response;
    });
  }

  bankCardShow = false;
  cashCardShow = false;
  radioValue: any;
  radioChange(event: any) {
    this.radioValue = event.target.defaultValue;
  }

  goToNext() {
    if (this.radioValue == 'bank') {
      this.bankCardShow = true;
    } else {
      this.cashCardShow = true;
    }
  }

  cancle() {
    this.bankCardShow = false;
    this.cashCardShow = false;
  }

  next() {
    this.bankCardShow = false;
    this.cashCardShow = false;
  }

}
