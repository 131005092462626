import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
    providedIn: 'root'
})

export class ApiProxy {
    user = {
        login() {
            return '/login.php'
        },
        signUp() {
            return '/signup.php'
        },
        personalDetail() {
            return '/personal.php'
        },
        personalInfo() {
            return '/personal_info.php'
        },
        loginPIN() {
            return '/set_pin.php'
        },
        dashboard() {
            return '/dashboard.php'
        },
        getCurrency() {
            return '/get_user_currency.php'
        },
        transactionDetail() {
            return '/transaction_details.php'
        },
        getTransactionLimit() {
            return '/transfer_limit.php'
        },
        getTransactionHistory() {
            return '/transaction_history.php'
        },
        event_list() {
            return '/machnet_event_list_test.php'
        },
        stripe_event_sender() {
            return '/stripe_event_sender.php'
        },
        event_ticket_emails() {
            return '/event_ticket_emails.php'
        },
        get_accounts() {
            return '/get_accounts.php'
        },
        stripe_ticket_availability() {
            return '/stripe_ticket_availability.php'
        },
        stripe_event_transaction_preview() {
            return '/stripe_event_transaction_preview.php'
        },
        get_profile() {
            return '/get_profile.php'
        },
        cardNumberCheck(cardNumber: any) {
            return `https://api.bincodes.com/cc/json/3756a73cee33d9d9e76d24408827eb8c/${cardNumber}`
        }
    }
}