import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatSelectModule } from "@angular/material/select";
import { DropdownModule } from "primeng/dropdown";
import { first } from "rxjs";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { CommonModule } from "@angular/common";
import { Router, RouterModule } from "@angular/router";
import { ApiService } from "../../../service/api.service";
import { environment } from "../../../../environments/environment";
import { SharedDataService } from "../../../service/shared/shared-data.service";

@Component({
  selector: "app-login",
  standalone: true,
  imports: [
    MatSelectModule,
    DropdownModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
  ],
  templateUrl: "./login.component.html",
  styleUrl: "./login.component.css",
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginForm: FormGroup;
  @ViewChild("myModal") myModal: ElementRef | undefined;

  constructor(
    private service: ApiService,
    public router: Router,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private sharedDataService: SharedDataService
  ) {
    this.loginForm = this.formBuilder.group({
      mobileNo: ["", Validators.required],
      countryISO: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  countryCodes: any = [];
  eventData: any;
  imageUrl: any;
  ngOnInit(): void {
    this.service
      .getCountriesCode()
      .pipe(first())
      .subscribe((response: any) => {
        this.countryCodes = response;
      });
    this.imageUrl = environment.image_url;
    this.eventTicketList();
  }

  ngAfterViewInit() {
    this.myModal?.nativeElement.classList.add("show");
    if (this.myModal?.nativeElement) {
      this.myModal.nativeElement.style.display = "block";
    }

    this.cdr.detectChanges();
  }

  closeModel() {
    if (this.myModal?.nativeElement) {
      this.myModal.nativeElement.classList.remove("show");
      this.myModal.nativeElement.style.display = "none";
      this.cdr.detectChanges();
    }
  }

  buttonDisabled: boolean = false;
  eventList: any;
  eventTicketList() {
    this.service
      .event_list()
      .pipe(first())
      .subscribe(
        (response: any) => {
          console.log(response);

          if (response?.data && response.data.length > 0) {
            this.eventList = response.data;
          } else {
            console.error("No data available in the response.");
            this.eventList = [];
          }
        },
        (error) => {
          console.error("An error occurred while fetching event data:", error);
          this.eventList = [];
        }
      );
  }

  onSubmit() {
    let countryCountry = this.countryCodes.find(
      (country: any) => country.code == this.loginForm.get("countryISO")?.value
    ).dial_code;
    console.log(countryCountry);

    let update = "1";
    const formData = new FormData();
    formData.append("user_mobile", this.loginForm.get("mobileNo")?.value);
    formData.append("user_password", this.loginForm.get("password")?.value);
    formData.append("updte", update);
    formData.append("country_code", countryCountry.replace("+", ""));
    formData.append("country_iso", this.loginForm.get("countryISO")?.value);
    // formData.append('device_type', '3');
    this.service
      .login(formData)
      .pipe(first())
      .subscribe((response: any) => {
        console.log(response);
        if (response) {
          if (response.error == 0) {
            localStorage.setItem("user", JSON.stringify(response.data));
            this.service.notify.showSuccess(response.error_msg);
            this.router.navigate(["/dashboard"]);
          } else {
            return this.service.notify.showSuccess(response.error_msg);
          }
        }
      });
  }

  buyTicket() {
    console.log("Selected Event ID:");

    const activeEvent = this.eventList.find(
      (event: any, index: any) => index === this.getActiveCarouselIndex()
    );

    if (activeEvent) {
      this.router.navigate([`/ticket_list/${activeEvent.event_id}`]);
    } else {
      console.error("No active event found");
    }

    this.staticUser();
  }

  staticUser() {
    const formData = new FormData();

    formData.append("first_name", "test"); //this.eventTicketForm.get('first_name')?.value);
    // formData.append('middle_name', this.eventTicketForm.get('middle_name')?.value);
    formData.append("last_name", "lName"); //this.eventTicketForm.get('last_name')?.value);
    formData.append("country_code", "+91"); //countryCode.replace('+', ''));
    formData.append(
      "user_mobile",
      String(Math.floor(1000000000 + Math.random() * 9000000000))
    ); //this.eventTicketForm.get('user_mobile')?.value);
    formData.append("user_email", "test@gmail.com"); //this.eventTicketForm.get('user_email')?.value);
    formData.append("address", "113 ,test testtest"); //this.eventTicketForm.get('address')?.value);
    formData.append("user_city", "test"); //this.eventTicketForm.get('user_city')?.value);
    formData.append("user_state", "test"); //this.eventTicketForm.get('user_state')?.value);
    formData.append("postal_code", "test"); //this.eventTicketForm.get('postal_code')?.value);
    formData.append("state_sort_code", "test"); //this.eventTicketForm.get('state_sort_code')?.value);
    formData.append("country_iso", "test"); //this.eventTicketForm.get('country_iso')?.value);
    formData.append("country", "test"); //this.eventTicketForm.get('country')?.value);
    formData.append("platform", environment.platform);

    this.service
      .stripe_event_sender(formData)
      .pipe(first())
      .subscribe((response: any) => {
        console.log(response);
        if (response) {
          this.sharedDataService.setData(response);
          this.router.navigate(["ticket_list"]);
        }
      });
  }

  getActiveCarouselIndex(): number {
    const items = document.querySelectorAll(".carousel-item");
    for (let i = 0; i < items.length; i++) {
      if (items[i].classList.contains("active")) {
        return i;
      }
    }
    return 0; // Default to the first item if no active item is found
  }
}
