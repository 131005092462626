<section style="height: 100vh;">
    <div class="container">
        <div class="row d-flex flex-wrap justify-content-center align-items-center">
            <div class="col-5">
                <div class="card login-form">
                    <div class="card-body py-4">
                        <div class="text-center">
                            <img src="../../../assets/inner-logo.png" alt="logo">
                            <h1 class="card-title text-danger text-center mb-4" style="font-size: 36px;">Sign Up</h1>
                        </div>
                        <div class="card-text">
                            @if(!mobileVerifed){
                            @if(!verificationId){
                            <form [formGroup]="mobileVerifyForm" (ngSubmit)="sendOtp()">
                                <div class="form-group py-3">
                                    <p-dropdown appendTo="body" [options]="countryCodes" optionValue="code"
                                        optionLabel="name" placeholder="Country Code" formControlName="countryISO"
                                        [filter]="true" styleClass="col-12 border-0">
                                    </p-dropdown>
                                </div>
                                <div class="form-group py-3">
                                    <input type="text" class="form-control border-0" id="exampleInputNumber"
                                        aria-describedby="emailHelp" formControlName="mobileNo"
                                        placeholder="Enter Phone Number" minlength="10" maxlength="10">
                                </div>
                                <div id="recaptcha-container"></div>
                                <div class="d-flex flex-wrap justify-content-between align-items-center py-4">
                                    <p class="text-dark" style=" font-size: 17px; line-height: 28px;">
                                        By clicking on next button you are agree to our <span class="text-danger">Tems &
                                            Condition</span>
                                        and <span class="text-danger">Privacy Policy</span>
                                    </p>
                                </div>
                                <div class="col-12">
                                    <button type="submit" class="col-12 btn btn-danger my-4 py-2">Next</button>
                                </div>
                            </form>
                            <div class="d-flex flex-wrap justify-content-center align-items-center py-4">
                                <a class="text-dark h6" [routerLink]="['/login']"
                                    style="cursor: pointer; text-decoration: none; font-weight: 600;">Already have an
                                    account?<span class="text-danger"> Login</span></a>
                            </div>
                            } @else {
                            <form [formGroup]="OTPVerifyForm" (ngSubmit)="verifyOtp()">
                                <div class="form-group">
                                    <div class="col-12 p-0">
                                        <input type="text" class="form-control border-0" id="exampleInputOTP"
                                            aria-describedby="otpHelp" formControlName="otp" placeholder="Enter OTP..."
                                            minlength="6" maxlength="6">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button type="submit" class="col-12 btn btn-danger my-4 py-2">Submit</button>
                                </div>
                            </form>
                            }
                            }
                            @else {
                            @if (!personalDetail) {
                            <form [formGroup]="signUpForm" (ngSubmit)="signUp()">
                                <div class="form-group py-3">
                                    <div class="col-12 p-0">
                                        <input type="text" class="form-control border-0" formControlName="fname"
                                            placeholder="Enter First Name">
                                    </div>
                                </div>
                                <div class="form-group py-3">
                                    <div class="col-12 p-0">
                                        <input type="text" class="form-control border-0" formControlName="lname"
                                            placeholder="Enter Last Name">
                                    </div>
                                </div>
                                <div class="form-group py-3">
                                    <div class="col-12 p-0">
                                        <input type="text" class="form-control border-0" formControlName="lolliname"
                                            placeholder="Enter Lolli Cash Name" minlength="6" maxlength="12">
                                    </div>
                                </div>
                                <div class="form-group py-3">
                                    <div class="col-12 p-0">
                                        <input type="text" class="form-control border-0" formControlName="email"
                                            placeholder="Enter Email">
                                    </div>
                                </div>
                                <div class="form-group py-3">
                                    <div class="col-12 p-0">
                                        <input type="password" class="form-control border-0" formControlName="password"
                                            placeholder="Enter Password">
                                    </div>
                                </div>
                                <div class="form-group py-3">
                                    <div class="col-12 p-0">
                                        <input type="password" class="form-control border-0" formControlName="cpassword"
                                            placeholder="Enter Confirm Password">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button type="submit" class="col-12 btn btn-danger my-4 py-2">Next</button>
                                </div>
                            </form>
                            } @else {
                            @if (!pin) {
                            <h2 class="text-center">Personal Detail</h2>
                            <form [formGroup]="personalDetailForm" (ngSubmit)="updateProfile()">
                                <div class="form-group py-3">
                                    <div class="col-12 p-0">
                                        <input type="date" class="form-control border-0" formControlName="dob"
                                            placeholder="Enter DOB">
                                    </div>
                                </div>
                                <div class="form-group py-3">
                                    <div class="col-12 p-0">
                                        <input type="text" autocorrect="off" autocapitalize="off" spellcheck="off"
                                            type="text" #search class="form-control border-0" formControlName="address"
                                            placeholder="Enter Address" (input)="onAddressInputChange($event)">
                                    </div>
                                </div>
                                <div class="form-group py-3">
                                    <div class="col-12 p-0">
                                        <input type="text" class="form-control border-0" formControlName="city"
                                            placeholder="Enter City">
                                    </div>
                                </div>
                                <div class="form-group py-3">
                                    <div class="col-12 p-0">
                                        <input type="text" class="form-control border-0" formControlName="state"
                                            placeholder="Enter State | Province | Region">
                                    </div>
                                </div>
                                <div class="form-group py-3">
                                    <div class="col-12 p-0">
                                        <input type="text" class="form-control border-0" formControlName="zip"
                                            placeholder="Enter Zip Code">
                                    </div>
                                </div>
                                <div class="form-group py-3">
                                    <div class="col-12 p-0">
                                        <input type="text" class="form-control border-0" formControlName="country"
                                            placeholder="Enter Country Name">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button type="submit" class="col-12 btn btn-danger my-4 py-2">Update
                                        Profile</button>
                                </div>
                            </form>
                            } @else {
                            <h2 class="text-center">Setup Account Security</h2>
                            <form [formGroup]="SecurityPINForm" (ngSubmit)="setSecurityPIN()">
                                <div class="form-group py-3">
                                    <div class="col-12 p-0">
                                        <input type="text" class="form-control border-0" formControlName="securityPIN"
                                            placeholder="Enter Security PIN" minlength="6" maxlength="6">
                                    </div>
                                </div>
                                <div class="form-group py-3">
                                    <div class="col-12 p-0">
                                        <input type="text" class="form-control border-0" formControlName="csecurityPIN"
                                            placeholder="Enter Confirm Security PIN" minlength="6" maxlength="6">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button type="submit" class="col-12 btn btn-danger my-4 py-2">Set PIN</button>
                                </div>
                            </form>
                            }
                            }
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>