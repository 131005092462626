<section style="background-color: #15202b;">

    <!-- Nav Bar Start -->
    <app-nav-bar></app-nav-bar>
    <!-- Nav Bar End -->

    <!-- History Section Start -->
    <section class="section" style="padding-top: 105px; height: 100vh;">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-8">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="pills-all-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all"
                                aria-selected="true">All Transactions</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-send-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-send" type="button" role="tab" aria-controls="pills-send"
                                aria-selected="false">Send Transactions</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-receive-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-receive" type="button" role="tab" aria-controls="pills-receive"
                                aria-selected="false">Receive Transactions</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-add-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-add" type="button" role="tab" aria-controls="pills-add"
                                aria-selected="false">Add Transactions</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active bg-light" id="pills-all" role="tabpanel"
                            aria-labelledby="pills-all-tab">
                            @if (transactionHistoryDetail.length > 0) {
                            @for (item of transactionHistoryDetail; track $index) {
                            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center py-2"
                                style="box-shadow: 0px 2px 2px lightcoral;">
                                <div class="col-2 text-center">
                                    @if(item.receiver_pic) {
                                    <img src="{{item.receiver_pic}}" alt="User Image" class="rounded-circle"
                                        style="width: 50px; object-fit: cover;">
                                    } @else {
                                    <img src="../../../assets/user-139.png" alt="User Image" class="rounded-circle"
                                        style="width: 50px; object-fit: cover;">
                                    }
                                </div>
                                <div class="col-8">
                                    <p class="fw-semibold mb-0 text-dark">
                                        {{item.title}}
                                    </p>
                                    <p class="mb-0 text-dark">
                                        {{item.transaction_status}}
                                    </p>
                                </div>
                                <div class="col-2">
                                    <button type="button" class="btn" [ngClass]="{
                                            'btn-success': item.transaction_status === 'Success',
                                            'btn-warning': item.transaction_status === 'Pending',
                                            'btn-danger': item.transaction_status === 'Cancelled'
                                          }">{{item.opp_user_currency}} {{item.amount}}</button>
                                </div>
                            </div>
                            }
                            } @else {
                            <p class="text-dark text-center">No Transaction Data</p>
                            }
                        </div>
                        <div class="tab-pane fade bg-light" id="pills-send" role="tabpanel"
                            aria-labelledby="pills-send-tab">
                            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center py-2"
                                style="box-shadow: 0px 2px 2px lightcoral;">
                                <div class="col-2 text-center">
                                    <img src="https://opensource.com/sites/default/files/delisa_alexander_headshot.jpg"
                                        alt="User Image" class="rounded-circle" style="width: 50px; object-fit: cover;">
                                </div>
                                <div class="col-8">
                                    <p class="fw-semibold mb-0 text-dark">
                                        Money received from
                                    </p>
                                    <p class="mb-0 text-dark">
                                        Success
                                    </p>
                                </div>
                                <div class="col-2">
                                    <button type="button" class="btn btn-success">INR 2</button>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center py-2"
                                style="box-shadow: 0px 2px 2px lightcoral;">
                                <div class="col-2 text-center">
                                    <img src="https://opensource.com/sites/default/files/delisa_alexander_headshot.jpg"
                                        alt="User Image" class="rounded-circle" style="width: 50px; object-fit: cover;">
                                </div>
                                <div class="col-8">
                                    <p class="fw-semibold mb-0 text-dark">
                                        Money received from
                                    </p>
                                    <p class="mb-0 text-dark">
                                        Success
                                    </p>
                                </div>
                                <div class="col-2">
                                    <button type="button" class="btn btn-success">INR 2</button>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center py-2"
                                style="box-shadow: 0px 2px 2px lightcoral;">
                                <div class="col-2 text-center">
                                    <img src="https://opensource.com/sites/default/files/delisa_alexander_headshot.jpg"
                                        alt="User Image" class="rounded-circle" style="width: 50px; object-fit: cover;">
                                </div>
                                <div class="col-8">
                                    <p class="fw-semibold mb-0 text-dark">
                                        Money sent to Eric Ackaah
                                    </p>
                                    <p class="mb-0 text-dark">
                                        Success
                                    </p>
                                </div>
                                <div class="col-2">
                                    <button type="button" class="btn btn-danger">INR 10</button>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center py-2"
                                style="box-shadow: 0px 2px 2px lightcoral;">
                                <div class="col-2 text-center">
                                    <img src="https://opensource.com/sites/default/files/delisa_alexander_headshot.jpg"
                                        alt="User Image" class="rounded-circle" style="width: 50px; object-fit: cover;">
                                </div>
                                <div class="col-8">
                                    <p class="fw-semibold mb-0 text-dark">
                                        Money received from
                                    </p>
                                    <p class="mb-0 text-dark">
                                        Success
                                    </p>
                                </div>
                                <div class="col-2">
                                    <button type="button" class="btn btn-success">INR 1</button>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center py-2"
                                style="box-shadow: 0px 2px 2px lightcoral;">
                                <div class="col-2 text-center">
                                    <img src="https://opensource.com/sites/default/files/delisa_alexander_headshot.jpg"
                                        alt="User Image" class="rounded-circle" style="width: 50px; object-fit: cover;">
                                </div>
                                <div class="col-8">
                                    <p class="fw-semibold mb-0 text-dark">
                                        Money received from
                                    </p>
                                    <p class="mb-0 text-dark">
                                        Success
                                    </p>
                                </div>
                                <div class="col-2">
                                    <button type="button" class="btn btn-success">INR 1</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade bg-light" id="pills-receive" role="tabpanel"
                            aria-labelledby="pills-receive-tab">
                            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center py-2"
                                style="box-shadow: 0px 2px 2px lightcoral;">
                                <div class="col-2 text-center">
                                    <img src="https://opensource.com/sites/default/files/delisa_alexander_headshot.jpg"
                                        alt="User Image" class="rounded-circle" style="width: 50px; object-fit: cover;">
                                </div>
                                <div class="col-8">
                                    <p class="fw-semibold mb-0 text-dark">
                                        Request money to
                                    </p>
                                    <p class="mb-0 text-dark">
                                        Pending
                                    </p>
                                </div>
                                <div class="col-2">
                                    <button type="button" class="btn btn-success">INR 2</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade bg-light" id="pills-add" role="tabpanel"
                            aria-labelledby="pills-add-tab">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- History Section End -->

    <!-- Footer Start -->
    <app-footer></app-footer>
    <!-- Footer End -->
</section>