<nav class="navbar navbar-light navbar-expand-lg bg-light fixed-top py-0">
    <div class="container">
        <a [routerLink]="['/dashboard']" class="navbar-brand">
            <img src="../../../../assets/inner-logo (1).png" style="width: 80px;" alt="lolliCash Logo">
        </a>

        <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fi fi-rr-bars-staggered"></i>
        </button>

        <div id="navbarCollapse" class="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a [routerLink]="['/dashboard']" [routerLinkActive]="'active'" class="nav-link">
                        Dashboard
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/history']" [routerLinkActive]="'active'" class="nav-link">
                        History
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/about_us']" [routerLinkActive]="'active'" class="nav-link">
                        About Us
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="['/profile']" [routerLinkActive]="'active'" class="nav-link">
                        Profile
                    </a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fi fi-sr-settings fs-5"></i>
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <li><a class="dropdown-item" [routerLink]="['/bank_information']" [routerLinkActive]="'active'">Bank Information</a></li>
                        <li><a class="dropdown-item" [routerLink]="['/send_money']" [routerLinkActive]="'active'">Send Money</a></li>
                        <li><a class="dropdown-item" [routerLink]="['/request_money']" [routerLinkActive]="'active'">Request Money</a></li>
                        <li><a class="dropdown-item" [routerLink]="['/account_support']" [routerLinkActive]="'active'">Account Support</a></li>
                        <li><a class="dropdown-item" [routerLink]="['/privacy_policy']" [routerLinkActive]="'active'">Privacy Policy</a></li>
                        <li><a class="dropdown-item" [routerLink]="['/terms_condotions']" [routerLinkActive]="'active'">Terms & Conditions</a></li>
                        <li><a class="dropdown-item" [routerLink]="['/login']" [routerLinkActive]="'active'">Logout</a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>