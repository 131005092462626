<section style="background-color: #15202b;">

    <!-- Nav Bar Start -->
    <app-nav-bar></app-nav-bar>
    <!-- Nav Bar End -->

    <!-- Receive Money Section Start -->
    <section class="section" style="padding-top: 105px; height: 100vh;">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-8">
                    <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="pills-all-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all"
                                aria-selected="true">Send Request</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-send-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-send" type="button" role="tab" aria-controls="pills-send"
                                aria-selected="false">Request Sent</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-receive-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-receive" type="button" role="tab" aria-controls="pills-receive"
                                aria-selected="false">Request Received</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-all" role="tabpanel"
                            aria-labelledby="pills-all-tab">
                            <div class="row mt-4">
                                @if (search === false) {
                                <h1 class="text-light text-center">SEARCH LOLLICASH USER</h1>
                                <div class="col-12 col-md-6 mx-auto card rounded-10 border-0 shadow-lg p-4 text-center"
                                    style="background: #FFEBEC;">
                                    <div class="col-12 card-body p-0 pt-4">
                                        <div class="form-group py-3 text-start">
                                            <p class="text-dark px-1">Enter LolliCash Name</p>
                                            <input type="text" value="LC" class="form-control border-0"
                                                placeholder="Enter LolliCash Name">
                                        </div>
                                        <div class="col-12 text-center">
                                            <button type="submit" class="col-4 btn btn-danger my-4 py-2"
                                                (click)="searchClick()">Search</button>
                                        </div>
                                    </div>
                                </div>
                                } @else {
                                <h1 class="text-light text-center">REQUEST PAYMENT</h1>
                                <div class="col-12 col-md-6 mx-auto card rounded-10 border-0 shadow-lg p-4 text-center"
                                    style="background: #FFEBEC;">
                                    <div class="col-12 card-body p-0 pt-4">
                                        <img src="../../../assets/user-139.png" alt="user Image" width="200"
                                            height="200">
                                        <h3 class="text-dark mt-3">daniel ryker</h3>
                                        <div class="d-flex flex-wrap justify-content-center">
                                            <div class="form-group py-3 text-start col-6">
                                                <input type="number" class="form-control border-0" placeholder="0.00"
                                                    style="box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, .25) !important;">
                                            </div>
                                        </div>
                                        <h3 style="background-color: #DC3545; display: inline-block; border-radius: 8px;"
                                            class="text-light p-2 border-rounded mb-0">USC</h3>
                                        <div class="d-flex flex-wrap justify-content-center">
                                            <div class="form-group py-3 text-start col-8">
                                                <input type="text" class="form-control border-0"
                                                    placeholder="Description"
                                                    style="box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, .25) !important;">
                                            </div>
                                        </div>
                                        <div class="col-12 text-center">
                                            <button type="submit" class="col-4 btn btn-outline-danger my-4 py-2 me-3"
                                                (click)="cancle()">Cancle</button>
                                            <button type="submit" class="col-4 btn btn-danger my-4 py-2"
                                                (click)="sendRequest()">Send
                                                Request</button>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div class="tab-pane fade bg-light" id="pills-send" role="tabpanel"
                            aria-labelledby="pills-send-tab">
                            <div class="col-12 d-flex flex-wrap justify-content-between align-items-start py-2"
                                style="box-shadow: 0px 2px 2px lightcoral;">
                                <div class="col-10 px-2">
                                    <h3 class="mb-0 text-dark">
                                        Sent to daniel ryker
                                    </h3>
                                    <h2 class="mb-0" style="color: #071e55;">
                                        USD 0.1
                                    </h2>
                                    <p class="mb-0 text-dark" style="font-size: 13px;">
                                        <i class="fi fi-sr-clock-three" style="vertical-align: middle;"></i> Unpaid
                                        03-21-2024 03:23 AM
                                    </p>
                                </div>
                                <div class="col-2">
                                    <button type="button" class="btn btn-danger">Cancle</button>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center py-2"
                                style="box-shadow: 0px 2px 2px lightcoral;">
                                <div class="col-10 px-2">
                                    <h3 class="mb-0 text-dark">
                                        Sent to daniel ryker
                                    </h3>
                                    <h2 class="mb-0" style="color: #071e55;">
                                        USD 1
                                    </h2>
                                    <p class="mb-0 text-dark" style="font-size: 13px;">
                                        <i class="fi fi-sr-clock-three" style="vertical-align: middle;"></i> Unpaid
                                        03-21-2024 02:53 AM
                                    </p>
                                </div>
                                <div class="col-2">
                                    <button type="button" class="btn btn-danger">Cancle</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade bg-light" id="pills-receive" role="tabpanel"
                            aria-labelledby="pills-receive-tab">
                            <div class="col-12 d-flex flex-wrap justify-content-between align-items-center py-2"
                                style="box-shadow: 0px 2px 2px lightcoral;">
                                <div class="col-10 px-2">
                                    <h3 class="mb-0 text-dark">
                                        Sent to daniel ryker
                                    </h3>
                                    <h2 class="mb-0" style="color: #071e55;">
                                        USD 1
                                    </h2>
                                    <p class="mb-0 text-dark" style="font-size: 13px;">
                                        <i class="fi fi-sr-clock-three" style="vertical-align: middle;"></i> Paid
                                        03-21-2024 02:53 AM
                                    </p>
                                </div>
                                <div class="col-2">
                                    <button type="button" class="btn btn-success">Success</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Receive Money Section End -->

    <!-- Footer Start -->
    <app-footer></app-footer>
    <!-- Footer End -->
</section>